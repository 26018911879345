import React, { Component } from 'react';
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  InputGroup,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Modal,
} from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import AutoAuthNavBar from '../AutoAuthNavBar';
import AutoAuthBreadcrumbs from '../../components/AutoAuthBreadcrumbs';
import { navbarSetup, resetUserData, nameValidate } from '../../libs/utils';
import { Button } from 'react-bootstrap';
import {
  getClientEnrollmentCode,
  getClientEnrollments,
  updateClientEnrollment,
  deleteClientEnrollment,
} from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import AlertModal from '../../components/AlertModal';
import ReactTable from 'react-table';
import '../../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageClientEnrollments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showGeneratedCodeModal: false,
      showEditModal: false,
      showDeleteModal: false,
      user: props.user,
      filter: '',
      newEnrollmentLabelFieldActivate: '',
      newEnrollmentLabel: '',
      newEnrollmentType: '',
      currentEnrollment: {}, // Current enrollment being processed (edit or delete)
      enrollments: null,
      enrollmentList: [],
      enrollmentCode: '',
      isLoading: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getClientEnrollments();
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          enrollments: result.enrollmentList || [],
          enrollmentList: result.enrollmentList ? result.enrollmentList : [],
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  // Sets verification state to only activate error checking when user types in field
  activateFieldVerify(field) {
    this.setState({ [field + 'Verify']: true });
  }

  handleShowEdit = (enrollment) => {
    this.setState({
      showEditModal: true,
      currentEnrollment: enrollment,
      newEnrollmentLabel: enrollment.label,
    });
  };

  handleEdit = async (currentEnrollment, type) => {
    this.setState({
      isLoading: true,
    });
    if (type === 'ENABLE' || type === 'DISABLE') {
      this.setState({
        showModal: true,
        alertMessage: 'Updating enrollment...',
      });
    }
    try {
      // Handle edit enrollment
      let result = await updateClientEnrollment(
        currentEnrollment.enrollmentCode,
        currentEnrollment.clientState,
        this.state.newEnrollmentLabel || currentEnrollment.label
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          isLoading: false,
          showEditModal: false,
          showModal: true,
          alertMessage: 'Enrollment successfully updated!',
        });
      }
    } catch (e) {
      this.setState({
        showDeleteModal: false,
        showEditModal: false,
        newEnrollmentLabel: '',
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
    let enrollments = this.state.enrollmentList;
    enrollments = enrollments.map((enrollment) => {
      if (enrollment.enrollmentCode === currentEnrollment.enrollmentCode) {
        enrollment.clientState = currentEnrollment.clientState;
        enrollment.label =
          this.state.newEnrollmentLabel || currentEnrollment.label;
      }
      return enrollment;
    });
    this.setState({
      enrollmentList: enrollments,
    });
  };

  handleDeleteButton = async (enrollment) => {
    this.setState({
      showDeleteModal: true,
      currentEnrollment: enrollment,
    });
  };

  handleConfirmModal = async () => {
    this.setState({
      isLoading: true
    })
    try {
      // Handle delete enrollment
      let result = await deleteClientEnrollment(
        this.state.currentEnrollment.enrollmentCode
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          showModal: true,
          isLoading: false,
          alertMessage: 'Enrollment successfully deleted!',
        });
      }
    } catch (e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        currentEnrollment: {},
        showModal: true,
        isLoading: false,
      });
    }

    let enrollments = this.state.enrollmentList;
    enrollments = enrollments.filter((enrollment) => {
      return (
        enrollment.enrollmentCode !==
        this.state.currentEnrollment.enrollmentCode
      );
    });

    this.setState({
      showDeleteModal: false,
      currentEnrollment: {},
      enrollmentList: enrollments,
    });
  };

  validateForm() {
    let nameValid = nameValidate(this.state.newEnrollmentLabel);

    return nameValid && this.state.newEnrollmentType !== '';
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.activateFieldVerify(event.target.id);
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(
          /[.*+\-?^${}()|[\]\\]/g,
          '\\$&'
        );
        const filterArr = processedStr
          ? processedStr.split(' ').filter((f) => f !== '')
          : [];
        const filterStr = filterArr.reduce((f1, f2) => f1 + '|' + f2);
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const enrollmentList = this.state.enrollmentList.filter((u) => {
          return (
            regexStr.test(u.enrollmentCode) ||
            regexStr.test(u.label) ||
            regexStr.test(u.enrollmentComplete)
          );
        });
        this.setState({
          enrollmentList: enrollmentList ? enrollmentList : [],
        });
      } else {
        this.setState({
          enrollmentList: this.state.enrollments,
        });
      }
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
      showEditModal: false,
      showGeneratedCodeModal: false,
      showDeleteModal: false,
      currentEnrollment: {},
      newEnrollmentLabel: '',
    });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });

    try {
      let result = await getClientEnrollmentCode(this.state.newEnrollmentLabel, this.state.newEnrollmentType);

      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState(
          {
            isLoading: false,
            newEnrollmentCode: result.enrollmentCode,
            newEnrollmentLabel: '',
            newEnrollmentLabelVerify: false,
          },
          async () => {
            this.setState({
              showGeneratedCodeModal: true,
            });
            let results = await getClientEnrollments();

            this.setState({
              enrollments: results.enrollmentList,
              enrollmentList: results.enrollmentList
                ? results.enrollmentList
                : [],
            });
          }
        );
      }
    } catch (e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        newEnrollmentCode: '',
        newEnrollmentLabel: '',
        newEnrollmentType: '',
        showModal: true,
        isLoading: false,
      });
    }
  };

  render() {
    const navbarData = navbarSetup(this.state);

    let enrollmentTypesOptions = [
      <option key="0" className="inactive" value="" disabled>Pick Enrollment Type</option>,
      <option key="1" value="CERT_ISSUANCE">CERT_ISSUANCE</option>,
      <option key="2" value="PRODUCT_SIGNING">PRODUCT_SIGNING</option>,
    ]


    const columnDefs = [];
    columnDefs.push({
      Header: 'Client ID',
      id: 'clientID',
      accessor: 'enrollmentCode',
      minWidth: 120,
      className: 'text-center',
      Cell: (row) => {
        return (
          <a
            href="#/"
            onClick={() => {
              navigator.clipboard.writeText(
                row.original.enrollmentCode ? row.original.enrollmentCode : ''
              );
            }}
          >
            {row.original.enrollmentCode}
          </a>
        );
      },
    });
    columnDefs.push({
      Header: 'Label',
      id: 'label',
      accessor: 'label',
      minWidth: 95,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Enrollment Type',
      id: 'enrollmentType',
      accessor: 'enrollmentType',
      minWidth: 95,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'State',
      id: 'clientState',
      accessor: 'clientState',
      minWidth: 50,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Added On',
      id: 'addedOn',
      accessor: 'createdOn',
      minWidth: 85,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Is Enrolled',
      id: 'isEnrolled',
      accessor: 'enrollmentComplete',
      minWidth: 50,
      className: 'text-center',
      Cell: (row) => {
        if (row.original.enrollmentComplete === true) {
          return 'TRUE';
        } else {
          return 'FALSE';
        }
      },
    });

    columnDefs.push({
      Header: 'Source IP',
      id: 'sourceIP',
      accessor: 'sourceIP',
      minWidth: 50,
      className: 'text-center',
      Cell: (row) => row.original.sourceIP || 'N/A',
    });

    columnDefs.push({
      Header: this.state.windowWidth > 500 ? 'Actions' : '',
      id: 'enrollmentActions',
      accessor: 'enrollmentCode',
      minWidth: 120,
      maxWidth: 120,
      className: 'text-center',
      Cell: (row) => {
        return (
          <div>
            <ButtonGroup>
              <div>
                <Button
                  bsStyle="link"
                  href="#"
                  onClick={() => this.handleShowEdit(row.original)}
                >
                  <Icon title="Edit Enrollment" name="edit outline" />
                </Button>

                {row.original.clientState === 'ACTIVE' ? (
                  <Button
                    bsStyle="link"
                    href="#"
                    onClick={() =>
                      this.handleEdit(
                        { ...row.original, clientState: 'INACTIVE' },
                        'DISABLE'
                      )
                    }
                  >
                    <Icon title="Disable Enrollment" name="ban" />
                  </Button>
                ) : (
                  <Button
                    bsStyle="link"
                    href="#"
                    onClick={() =>
                      this.handleEdit(
                        { ...row.original, clientState: 'ACTIVE' },
                        'ENABLE'
                      )
                    }
                  >
                    <Icon
                      title="Enable Enrollment"
                      name="check circle outline"
                    />
                  </Button>
                )}

                <Button
                  bsStyle="link"
                  href="#"
                  onClick={() => this.handleDeleteButton(row.original)}
                >
                  <Icon title="Delete Enrollment" name="times circle outline" />
                </Button>
              </div>
            </ButtonGroup>
          </div>
        );
      },
      sortable: false,
      filterable: false,
    });

    let enrollments =
      this.state &&
      this.state.enrollmentList &&
      this.state.enrollmentList.length > 0
        ? this.state.enrollmentList.map((enrollment) => {
            return {
              enrollmentCode: enrollment.enrollmentCode,
              enrollmentType: enrollment.enrollmentType,
              label: enrollment.label,
              createdOn: enrollment.createdOn,
              enrollmentComplete: enrollment.enrollmentComplete,
              clientState: enrollment.clientState,
              sourceIP: enrollment.sourceIP,
              redeemedOn: enrollment.redeemedOn,
            };
          })
        : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}
        ></AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="manage-client-enrollments-tab">
            <Tab key="1" eventKey={1} title="Client Enrollments">
              <TabContent>
                {this.state.enrollments &&
                this.state.enrollments.length >= 0 ? (
                  <div
                    className={
                      this.state.windowWidth > 400
                        ? 'col-lg-12 col-md-11'
                        : 'col-lg-10 col-md-11 no-left-padding'
                    }
                  >
                    <br />
                    <div className="auto-auth-table col-sm-12">
                      <FormGroup controlId="filter" bsSize="large">
                        <ControlLabel
                          className={
                            this.state.filterFieldActivate ||
                            this.state.filter.length > 0
                              ? 'float-label field-active'
                              : 'float-label'
                          }
                        >
                          Filter
                        </ControlLabel>
                        <FormControl
                          maxLength="50"
                          type="text"
                          value={this.state.filter}
                          onChange={this.handleChange.bind(this)}
                          onFocus={this.activateField.bind(this)}
                          onBlur={this.disableField.bind(this)}
                        />
                      </FormGroup>
                      <ReactTable
                        columns={columnDefs}
                        data={enrollments}
                        noDataText={'No Enrollments Found'}
                        className="-highlight"
                        defaultPageSize={10}
                        defaultSorted={[
                          {
                            id: 'label',
                            desc: false,
                          },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                )}
              </TabContent>
            </Tab>
            <Tab key="2" eventKey={2} title="Generate Enrollment Code">
              <TabContent>
                <div className="col-sm-4">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <br />
                      <h3 className="text-center">
                        Get a Client Enrollment Code
                      </h3>
                    </FormGroup>
                    <FormGroup controlId="newEnrollmentLabel" bsSize="large">
                      <div className="select-container-pad-20 col-sm-12">
                        <ControlLabel
                          className={
                            this.state.newEnrollmentLabelFieldActivate ||
                            this.state.newEnrollmentLabel.length > 0
                              ? 'float-label field-active'
                              : 'float-label'
                          }
                        >
                          Enrollment Label
                        </ControlLabel>
                        <FormControl
                          className={
                            this.state.newEnrollmentLabelVerify &&
                            !nameValidate(this.state.newEnrollmentLabel)
                              ? 'error'
                              : ''
                          }
                          maxLength="50"
                          type="text"
                          value={this.state.newEnrollmentLabel}
                          onChange={this.handleChange.bind(this)}
                          onFocus={this.activateField.bind(this)}
                          onBlur={this.disableField.bind(this)}
                        />
                        {this.state.newEnrollmentLabelVerify &&
                        !nameValidate(this.state.newEnrollmentLabel) ? (
                          <HelpBlock className="error">
                            Enrollment label cannot be blank and cannot contain
                            special characters
                          </HelpBlock>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="select-container-pad-20 col-sm-12">
                          <select
                            value={this.state.newEnrollmentType}
                            className="col-sm-12 select-custom"
                            onChange={this.handleChange.bind(this)}
                            id="newEnrollmentType" >
                            { enrollmentTypesOptions.map(o => o ) }
                          </select>
                      </div>
                    </FormGroup>
                    <div className="btn-container">
                      <LoaderButton
                        id="submit-button"
                        className="red-button"
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Generate"
                        loadingText="Generating..."
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6"></div>
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel}
          isLoading={this.state.isLoading}
        />

        <Modal
          show={this.state.showGeneratedCodeModal}
          onHide={this.handleCancel}
        >
          <Modal.Header closeButton>
            <Modal.Title>Client Enrollment Code Generated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your Client Enrollment code is:{' '}
            <b>{this.state.newEnrollmentCode}</b>
            <br />
            Please copy it and use it to enroll your client with AutoAuth.
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  this.state.newEnrollmentCode
                    ? this.state.newEnrollmentCode
                    : ''
                );
              }}
            >
              Copy Code
            </Button>
            <Button bsStyle="primary" onClick={this.handleCancel}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showEditModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client Enrollment Label</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormGroup controlId="newEnrollmentLabel" bsSize="large">
                <ControlLabel
                  className={
                    this.state.newEnrollmentLabelFieldActivate ||
                    this.state.newEnrollmentLabel.length > 0
                      ? 'float-label field-active'
                      : 'float-label'
                  }
                >
                  Enrollment Label
                </ControlLabel>
                <FormControl
                  className={
                    this.state.newEnrollmentLabelVerify &&
                    !nameValidate(this.state.newEnrollmentLabel)
                      ? 'error'
                      : ''
                  }
                  maxLength="50"
                  type="text"
                  value={this.state.newEnrollmentLabel}
                  onChange={this.handleChange.bind(this)}
                  onFocus={this.activateField.bind(this)}
                  onBlur={this.disableField.bind(this)}
                />
                {this.state.newEnrollmentLabelVerify &&
                !nameValidate(this.state.newEnrollmentLabel) &&
                this.state.showEditModal ? (
                  <HelpBlock className="error">
                    Enrollment label cannot be blank and cannot contain special
                    characters
                  </HelpBlock>
                ) : (
                  ''
                )}
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Updating..."
              onClick={() => this.handleEdit(this.state.currentEnrollment)}
            />
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Enrollment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete the enrollment:{' '}
              {this.state.currentEnrollment.label}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Deleting..."
              onClick={this.handleConfirmModal}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
