import React, { Component } from "react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import { navbarSetup, resetUserData } from "../libs/utils";
import AlertModal from "../components/AlertModal";
import "./Support.css";
import {
  Glyphicon,
  Row,
} from "react-bootstrap";


// Detect Windows OS
function detectWindowsOS(osStr) {
  switch(osStr) {
    case 'Windows 95':
    case 'Win95':
    case 'Windows_95':
      return 'Windows 95';
      
    case 'Windows 98':
    case 'Win98': 
      return 'Windows 98';
      
    case 'Windows NT 5.0':
    case 'Windows 2000':
      return 'Windows 2000';
      
    case 'Windows NT 5.1':
    case 'Windows XP':
      return 'Windows XP';
      
    case 'Windows NT 5.2':
      return 'Windows Server 2003';
      
    case 'Windows NT 6.0':
      return 'Windows Vista';
      
    case 'Windows NT 6.1':
      return 'Windows 7';
      
    case 'Windows NT 6.2':
    case 'Windows NT 6.3':
    case 'WOW64':
      return 'Windows 8';
      
    case 'Windows 10.0':
    case 'Windows NT 10.0':
      return 'Windows 10';
      
    case 'Windows NT 4.0':
    case 'WinNT4.0':
    case 'WinNT':
    case 'Windows NT':
      return 'Windows NT 4.0';
      
    case 'Windows ME':
      return 'Windows ME';
      
    case 'OpenBSD':
      return 'Open BSD';
      
    case 'SunOS':
      return 'Sun OS';
      
    case 'Linux':
    case 'X11':
      return 'Linux';
      
    default:
      return osStr;
  }
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {  
  let ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return {
      name: "Internet Explorer",
      version: parseFloat(ua.substring(msie + 5))
    }
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return {
      name: "Internet Explorer",
      version: parseFloat(ua.substring(rv + 3))
    }
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return {
      name: "Edge",
      version: parseFloat(ua.substring(edge + 5))
    }
  }

  // other browser
  return false;
}

export default class Support extends Component {
  
  constructor(props) {
    super(props);

    let objappVersion = navigator.appVersion;
    let objAgent = navigator.userAgent;
    let objbrowserName  = navigator.appName;
    let objfullVersion  = ''+parseFloat(objappVersion); 
    let objBrMajorVersion = parseInt(objappVersion,10);
    let objOffsetName,objOffsetVersion,ix;
    let hostOSStart = objAgent.indexOf("(") + 1;
    let hostOSEnd = objAgent.indexOf(")");
    let tmpOSStr = objAgent.substring(hostOSStart, hostOSEnd);
    
    let objHostOS;
    if (tmpOSStr.indexOf('Mac OS') > 0) {
      objHostOS = tmpOSStr.substring(tmpOSStr.indexOf(";")+1).replace(/_/g, '.');
    } else {
      objHostOS = detectWindowsOS(tmpOSStr.substring(0, tmpOSStr.indexOf(';')));
    }
    
    let browser=detectIE();
    
    if (browser) { 
      objbrowserName = browser.name;
      objfullVersion = browser.version;
    } else {
    
      // In Chrome 
      if ((objOffsetVersion=objAgent.indexOf("Chrome")) !== -1) {
       objbrowserName = "Chrome";
       objfullVersion = objAgent.substring(objOffsetVersion+7);
      }
      // In Firefox
      else if ((objOffsetVersion=objAgent.indexOf("Firefox"))!==-1) {
        objbrowserName = "Firefox";
        objfullVersion = objAgent.substring(objOffsetVersion+8);
        if (tmpOSStr.indexOf('Mac OS') > 0) {
          objHostOS = navigator.oscpu;
        } else {
          objHostOS = detectWindowsOS(navigator.oscpu.substring(0, navigator.oscpu.indexOf(';')));
        }
      }
      // In Safari 
      else if ((objOffsetVersion=objAgent.indexOf("Safari"))!==-1) {
        objbrowserName = "Safari";
        objfullVersion = objAgent.substring(objOffsetVersion+7);
        if ((objOffsetVersion=objAgent.indexOf("Version"))!==-1) 
          objfullVersion = objAgent.substring(objOffsetVersion+8);
      }
      // For other browser "name/version" is at the end of userAgent 
      else if ( (objOffsetName=objAgent.lastIndexOf(' ')+1) < 
                  (objOffsetVersion=objAgent.lastIndexOf('/')) ) 
      {
        objbrowserName = objAgent.substring(objOffsetName,objOffsetVersion);
        objfullVersion = objAgent.substring(objOffsetVersion+1);
        if (objbrowserName.toLowerCase()===objbrowserName.toUpperCase()) {
          objbrowserName = navigator.appName;
        }
      }
      // trimming the fullVersion string at semicolon/space if present
      if ((ix=objfullVersion.indexOf(";"))!==-1)
         objfullVersion=objfullVersion.substring(0,ix);
      if ((ix=objfullVersion.indexOf(" "))!==-1)
         objfullVersion=objfullVersion.substring(0,ix);
      
      objBrMajorVersion = parseInt(''+objfullVersion,10);
      if (isNaN(objBrMajorVersion)) {
        objfullVersion  = ''+parseFloat(navigator.appVersion); 
        objBrMajorVersion = parseInt(navigator.appVersion,10);
      }
    }

    this.state = {
      user: props.user,
      isLoading: true,
      browserName: objbrowserName,
      fullVersion: objfullVersion,
      navigatorAppName: navigator.appName,
      hostOS: objHostOS
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) { 
      return;
    }
    try {
      await resetUserData(this, true);
      if (this.state.dataRetrieveError) {
        this.setState({
          alertMessage: this.state.dataRetrieveError,
          showModal: true,
          isLoading: false
        });
      } else {
        this.props.updateUserInfo(this.state.user);
        this.setState({isLoading: false});
      }
    } catch (e) {
      console.log("Error getting User Info: ", e);
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  handleCancel = () => {
    this.setState({ showModal: false });
  }
  
  renderLander() {

    const navbarData = navbarSetup(this.state);
    
    let shops = this.state && this.state.user && this.state.user.shops ?
      this.state.user.shops.map((shop) => {
        return {
          shopID: shop.shopID,
          name: shop.shopName,
          state: shop.shopUserState
        }
      })
      : [];
    
    return (
      <div>
        { this.state && this.state.user ?
        <div key="menubar" className="home">
          <AutoAuthNavBar 
            manufacturer={navbarData.thisIsAManufacturer} 
            serviceCenter={navbarData.thisIsAServiceCenter} 
            name={navbarData.name} 
            shop={navbarData.shop} 
            shops={shops}
            shopRole={navbarData.shopRole} 
            shopState={navbarData.shopState} 
            emailVerified={navbarData.emailVerified} 
            userHasAuthenticated={this.props.userHasAuthenticated}
            pathname={this.props.location.pathname}>
          </AutoAuthNavBar>
          <Row key="welcome" className="panel welcome">
            <div>Support - Coming Soon</div>
          </Row>
          
        </div>
        : this.state.dataRetrieveError ?
        null 
        :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
        }
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }

  renderNotAuthenticated() {

    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): ''}
      </div>
    );
  }
}
