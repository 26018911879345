import { getUserRecord } from "../libs/db-lib";

// IE Polyfill
if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

export function navbarSetup(state) {
  let returnState = {};
  const toolManufacturers = state && state.user?state.user.toolManufacturers:null;
  const serviceCenters = state && state.user?state.user.userShops:null;

  let emailVerified = false;
  if (state && state.user) {
    if (state.user.authProvider === "DIRECTORY") {
      emailVerified = true;
    } else {
      if (state.user.emailVerified) {
        emailVerified = state.user.emailVerified;
      }
    }
  }
  returnState.thisIsAManufacturer = (toolManufacturers && toolManufacturers.length);
  returnState.thisIsAServiceCenter = (serviceCenters && serviceCenters.length);

  returnState.name = state && state.user?state.user.firstName + ' ' + state.user.lastName:'';
  returnState.shop = state && state.user && state.user.shop?state.user.shop:'';
  returnState.shopRole = state && state.user && state.user.shopRole?state.user.shopRole:'';
  returnState.shopState = state && state.user && state.user.shopState?state.user.shopState:'';
  returnState.emailVerified = emailVerified;

  return returnState;
}

export function fieldChangeCase(self, eventTarget, targetCase) {
  let field = document.getElementById(eventTarget.id);
  let cursorLocation = eventTarget.selectionStart;
  let currentValue = field.value;
  let newValue;
  if (targetCase === "lower") {
    newValue = currentValue.toLowerCase();
  } else {
    newValue = currentValue.toUpperCase();
  }
  field.value = newValue;
  self.setState({
    [eventTarget.id]: newValue
  });

  field.setSelectionRange(cursorLocation, cursorLocation);
}

export async function resetUserData(currentThis) {
  const userRec = await getUserRecord();
  if (!userRec.error) {

    currentThis.setState({user: {'firstName': userRec.firstName,
                                 'lastName' : userRec.lastName,
                                 'email'    : userRec.email,
                                 'emailVerified' : userRec.emailVerified,
                                 'username' : userRec.userName,
                                 'userType' : userRec.userType,
                                 'user'     : userRec,
                                 'userRoleMask' : userRec.userRoleMask,
                                 'authProvider' : userRec.authProvider,
                                },
                         });
    currentThis.setState({groups: []});
  } else {
    if (userRec.error === "Unauthorized") {
      currentThis.props.history.push("/");
    } else {
      currentThis.setState({dataRetrieveError: userRec.error});
    }
  }

}

export function formatUsers(users) {
  return users?users.toString():'';
}

export function passwordValidate(password) {
  let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?/(){}=:;\"'<>[\\]\\\\])(?=.{8,})");
  return typeof password === 'string' && password.length <= 50 && strongRegex.test(password);
}

export function usernameValidate(username) {
  let usernameRegex = new RegExp("^([a-zA-Z]{1})([a-zA-Z0-9._]{6,})$");
  return typeof username === 'string' && usernameRegex.test(username) && username.length <= 50;
}

export function nameValidate(name) {
  let nameRegex = new RegExp("^([a-zA-Z0-9- .'`‘’]+)$");
  return typeof name === 'string' && nameRegex.test(name) && name.length <= 50;
}

export function isValidEmail(email) {
  // the internet told me this matches 99% of email addresses
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let lowerEmail = email ? email.toLowerCase() : '';
  return typeof email === 'string' && re.test(lowerEmail) && email.length <= 100;
};

export function toolSerialNoValidate(serialNo) {
  let toolSerialNoRegex = new RegExp("^[a-zA-Z0-9-_/.]+$");
  return typeof serialNo === 'string' && toolSerialNoRegex.test(serialNo) && serialNo.length <= 100;
}

// Detect Windows OS
function detectWindowsOS(osStr) {
  switch(osStr) {
    case 'Windows 95':
    case 'Win95':
    case 'Windows_95':
      return 'Windows 95';

    case 'Windows 98':
    case 'Win98':
      return 'Windows 98';

    case 'Windows NT 5.0':
    case 'Windows 2000':
      return 'Windows 2000';

    case 'Windows NT 5.1':
    case 'Windows XP':
      return 'Windows XP';

    case 'Windows NT 5.2':
      return 'Windows Server 2003';

    case 'Windows NT 6.0':
      return 'Windows Vista';

    case 'Windows NT 6.1':
      return 'Windows 7';

    case 'Windows NT 6.2':
    case 'Windows NT 6.3':
    case 'WOW64':
      return 'Windows 8';

    case 'Windows 10.0':
    case 'Windows NT 10.0':
      return 'Windows 10';

    case 'Windows NT 4.0':
    case 'WinNT4.0':
    case 'WinNT':
    case 'Windows NT':
      return 'Windows NT 4.0';

    case 'Windows ME':
      return 'Windows ME';

    case 'OpenBSD':
      return 'Open BSD';

    case 'SunOS':
      return 'Sun OS';

    case 'Linux':
    case 'X11':
      return 'Linux';

    default:
      return osStr;
  }
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  let ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return {
      name: "Internet Explorer",
      version: parseFloat(ua.substring(msie + 5))
    }
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return {
      name: "Internet Explorer",
      version: parseFloat(ua.substring(rv + 3))
    }
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return {
      name: "Edge",
      version: parseFloat(ua.substring(edge + 5))
    }
  }

  // other browser
  return false;
}

export function systemInfo() {

    let objappVersion = navigator.appVersion;
    let objAgent = navigator.userAgent;
    let objbrowserName  = navigator.appName;
    let objfullVersion  = ''+parseFloat(objappVersion);
    let objBrMajorVersion = parseInt(objappVersion,10);
    let objOffsetName,objOffsetVersion,ix;
    let hostOSStart = objAgent.indexOf("(") + 1;
    let hostOSEnd = objAgent.indexOf(")");
    let tmpOSStr = objAgent.substring(hostOSStart, hostOSEnd);

    let objHostOS;
    if (tmpOSStr.indexOf('Mac OS') > 0) {
      objHostOS = tmpOSStr.substring(tmpOSStr.indexOf(";")+1).replace(/_/g, '.');
    } else {
      objHostOS = detectWindowsOS(tmpOSStr.substring(0, tmpOSStr.indexOf(';')));
    }

    let browser=detectIE();

    if (browser) {
      objbrowserName = browser.name;
      objfullVersion = browser.version;
    } else {

      // In Chrome
      if ((objOffsetVersion=objAgent.indexOf("Chrome")) !== -1) {
       objbrowserName = "Chrome";
       objfullVersion = objAgent.substring(objOffsetVersion+7);
      }
      // In Firefox
      else if ((objOffsetVersion=objAgent.indexOf("Firefox"))!==-1) {
        objbrowserName = "Firefox";
        objfullVersion = objAgent.substring(objOffsetVersion+8);
        if (tmpOSStr.indexOf('Mac OS') > 0) {
          objHostOS = navigator.oscpu;
        } else {
          objHostOS = detectWindowsOS(navigator.oscpu.substring(0, navigator.oscpu.indexOf(';')));
        }
      }
      // In Safari
      else if ((objOffsetVersion=objAgent.indexOf("Safari"))!==-1) {
        objbrowserName = "Safari";
        objfullVersion = objAgent.substring(objOffsetVersion+7);
        if ((objOffsetVersion=objAgent.indexOf("Version"))!==-1)
          objfullVersion = objAgent.substring(objOffsetVersion+8);
      }
      // For other browser "name/version" is at the end of userAgent
      else if ( (objOffsetName=objAgent.lastIndexOf(' ')+1) <
                  (objOffsetVersion=objAgent.lastIndexOf('/')) )
      {
        objbrowserName = objAgent.substring(objOffsetName,objOffsetVersion);
        objfullVersion = objAgent.substring(objOffsetVersion+1);
        if (objbrowserName.toLowerCase()===objbrowserName.toUpperCase()) {
          objbrowserName = navigator.appName;
        }
      }
      // trimming the fullVersion string at semicolon/space if present
      if ((ix=objfullVersion.indexOf(";"))!==-1)
         objfullVersion=objfullVersion.substring(0,ix);
      if ((ix=objfullVersion.indexOf(" "))!==-1)
         objfullVersion=objfullVersion.substring(0,ix);

      objBrMajorVersion = parseInt(''+objfullVersion,10);
      if (isNaN(objBrMajorVersion)) {
        objfullVersion  = ''+parseFloat(navigator.appVersion);
      }
    }

    return {
      hostOS: objHostOS,
      browserName: objbrowserName,
      browserVersion: objfullVersion
    }

}


