import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Panel,
  Modal,
} from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import CustomDatePicker from '../components/CustomDatePicker';
import moment from 'moment';
import AutoAuthNavBar from './AutoAuthNavBar';
import AutoAuthBreadcrumbs from '../components/AutoAuthBreadcrumbs';
import { navbarSetup, resetUserData } from '../libs/utils';
import { Button } from 'react-bootstrap';
import { getSignActivity } from '../libs/db-lib';
import AlertModal from '../components/AlertModal';
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class SignRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showInfoModal: false,
      user: props.user,
      filter: '',
      signRequests: null,
      signRequestsList: [],
      currentProduct: {},
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      isLoading: false,

      open: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getSignActivity(
        this.state.startDate,
        this.state.endDate
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          signRequests: result.logEntries || [],
          signRequestsList: result.logEntries ? result.logEntries : [],
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(
          /[.*+\-?^${}()|[\]\\]/g,
          '\\$&'
        );
        const filterArr = processedStr
          ? processedStr.split(' ').filter((f) => f !== '')
          : [];
        const filterStr = filterArr.reduce((f1, f2) => f1 + '|' + f2);
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const signRequestsList = this.state.signRequestsList.filter((u) => {
          return (
            regexStr.test(u.requestID) ||
            regexStr.test(u.clientID) ||
            regexStr.test(u.addedOn)
          );
        });
        this.setState({
          signRequestsList: signRequestsList ? signRequestsList : [],
        });
      } else {
        this.setState({
          signRequestsList: this.state.signRequests,
        });
      }
    }
  };

  handleRangeChange = async (value) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    }, () => {
      this.setState({
        isLoading: true
      })
    });

    try {
      let result = await getSignActivity(
        this.state.startDate,
        this.state.endDate
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          signRequests: result.logEntries || [],
          signRequestsList: result.logEntries ? result.logEntries : [],
          isLoading: false
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }

    let filteredList = this.state.signRequests.filter((request) => {
      let momentDate = moment(request.addedOn);
      return value.start < momentDate && value.end > momentDate;
    });
    this.setState({
      signRequestsList: filteredList,
    });
  };

  handleShowInfoModal = (product) => {
    this.setState({
      currentProduct: product,
      showInfoModal: true,
    });
  };

  handleCancelModal = (e) => {
    this.setState({
      showModal: false,
      showInfoModal: false,
    });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

// Handle dummy download, for testing
  download = (isCsr, name, data) => {
    let formattedData = this.buildCertCsr(isCsr, data);

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(formattedData)
    );
    element.setAttribute('download', isCsr ? `${name}.csr` : `${name}.cer`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  buildCertCsr = (isCsr, data) => {
    let retStr = isCsr
      ? '-----BEGIN CERTIFICATE REQUEST-----'
      : '-----BEGIN CERTIFICATE-----';
    retStr += '\n';

    while (data.length > 0) {
      retStr += data.substr(0, 64) + '\n';
      data = data.substr(64);
    }
    retStr += isCsr
      ? '-----END CERTIFICATE REQUEST-----'
      : '-----END CERTIFICATE-----';

    return retStr;
  };

  buildCsv = () => {
    const header = ["Added On", "Client ID", "Client Label", "Request ID",
                    "Product ID", "Request Status", "Error Message",
                    "User First Name", "User ID", "User Last Name",
                    "User Location", "User Source IP", "User Type"];


    let csvContent = header + "\r\n";

    this.state.signRequestsList.forEach((request) => {
      delete request.partitionID;
      let sortedKeys = ['addedOn', 'clientID', 'clientLabel', 'requestID',
                        'productID', 'requestStatus', 'errorMessage',
                        'userFirstName', 'userID', 'userLastName',
                        'userLocation', 'userSourceIP', 'userType'];
      let rowArray = [];

      for (let key of sortedKeys) {
        if (!request[key]) {
          rowArray.push("N/A");
          continue;
        }
        if (typeof request[key] === 'string') {
          rowArray.push(request[key] ? request[key].replace(/,/g, '-') : "N/A");
        } else {
          if (typeof request[key] === 'object') {
            rowArray.push(Object.keys(request[key]).length > 0 ? JSON.stringify(request[key]) : "N/A");
          }
        }
      }

      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent)
    );
    element.setAttribute('download', `${this.state.startDate.format("YYYY-MM-DD")} to ${this.state.endDate.format("YYYY-MM-DD")}-CSRs.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push({
      Header: 'Client Label',
      id: 'clientLabel',
      accessor: 'clientLabel',
      minWidth: 100,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Request ID',
      id: 'requestID',
      accessor: 'requestID',
      minWidth: 100,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Added On',
      id: 'addedOn',
      accessor: 'addedOn',
      minWidth: 80,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'User ID',
      id: 'userID',
      accessor: 'userID',
      minWidth: 50,
      className: 'text-center',
    });

    columnDefs.push({
      Header: 'Request Status',
      id: 'requestStatus',
      accessor: 'requestStatus',
      minWidth: 50,
      className: 'text-center',
      Cell: (row) => {
        if (!row.original.requestStatus) {
          return "SUCCESS"
        }
        return row.original.requestStatus;
      }
    });

    columnDefs.push({
      Header: this.state.windowWidth > 500 ? 'Actions' : '',
      id: 'signRequestActions',
      accessor: 'requestID',
      minWidth: 100,
      maxWidth: 100,
      className: 'text-center',
      Cell: (row) => {
        return (
          <div>
            <ButtonGroup>
              <div>
                <Button
                  bsStyle="link"
                  href="#"
                  onClick={() => this.handleShowInfoModal(row.original)}
                >
                  <Icon title="Info" name="info circle" />
                </Button>
              </div>
            </ButtonGroup>
          </div>
        );
      },
      sortable: false,
      filterable: false,
    });

    let signRequests =
      this.state &&
      this.state.signRequestsList &&
      this.state.signRequestsList.length > 0
        ? this.state.signRequestsList.map((request) => {
            return request;
          })
        : [];

      let loadingText = <p>
        Loading client requests from <strong>{this.state.startDate.format("YYYY-MM-DD")}</strong> to <strong>{this.state.endDate.format("YYYY-MM-DD")}</strong>
      </p>

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    let productInfoMap = [
      ['clientLabel', 'Client Label'],
      ['clientID', 'Client ID'],
      ['productID', 'Product ID'],
    ].map(([key, value]) => {
      let retVal = "N/A";
      if (typeof this.state.currentProduct[key] === 'string') {
        retVal = this.state.currentProduct[key] || "N/A"
      } else if (typeof this.state.currentProduct[key] === 'object') {
        retVal = Object.keys(this.state.currentProduct[key]).length > 0 ? JSON.stringify(this.state.currentProduct[key]) : "N/A";
      }
      return (
        <tr key={key}>
          <th>{value}:</th>
          <td>{retVal}</td>
        </tr>
      );
    });

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}
        ></AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="client-requests-tab">
            <Tab key="1" eventKey={1} title="Client Requests">
              <TabContent>
                {this.state.signRequests &&
                this.state.signRequests.length >= 0 ? (
                  <div
                    className={
                      this.state.windowWidth > 400
                        ? 'col-lg-12 col-md-11'
                        : 'col-lg-10 col-md-11 no-left-padding'
                    }
                  >
                    <br />
                    <div className="col-sm-9 center text-bold">Date Range:</div>
                    <br />
                    <br />
                    <div className="flex-container align-flex-start col-sm-9 center">
                      <CustomDatePicker
                        value={{
                          start: this.state.startDate,
                          end: this.state.endDate,
                          name: 'Last 30 Days',
                        }}
                        onChange={this.handleRangeChange}
                      />
                      <Button
                        bsStyle="primary"
                        onClick={this.buildCsv}
                        className="spacer15"
                      >
                        <Icon name="file alternate" />
                        Export CSV
                      </Button>
                    </div>

                    <br />
                    <div className="auto-auth-table col-sm-12">
                      <FormGroup controlId="filter" bsSize="large">
                        <ControlLabel
                          className={
                            this.state.filterFieldActivate ||
                            this.state.filter.length > 0
                              ? 'float-label field-active'
                              : 'float-label'
                          }
                        >
                          Filter
                        </ControlLabel>
                        <FormControl
                          maxLength="50"
                          type="text"
                          value={this.state.filter}
                          onChange={this.handleChange}
                          onFocus={this.activateField}
                          onBlur={this.disableField}
                        />
                      </FormGroup>
                      <ReactTable
                        columns={columnDefs}
                        data={signRequests}
                        noDataText={'No Sign Requests Found'}
                        className="-highlight"
                        defaultPageSize={10}
                        defaultSorted={[
                          {
                            id: 'label',
                            desc: false,
                          },
                        ]}
                        loading={this.state.isLoading}
                        loadingText={loadingText}
                        getTrProps={(state, row, col, instance) => {
                          if (row && row.original.requestStatus === 'ERROR') {
                            return {
                              style: {
                                color: "red",
                                fontWeight: "bold"
                              }
                            }
                          }
                          return {};
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                )}
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancelModal}
        ></AlertModal>

        <Modal
          fullscreen="true"
          show={this.state.showInfoModal}
          onHide={this.handleCancelModal}
          dialogClassName="panel-frame percent60"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.currentProduct.clientLabel} |{' '}
              {this.state.currentProduct.requestID}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex-container flex-container-center">
              { (this.state.currentProduct && this.state.currentProduct.errorMessage) ?
              <Panel bsStyle="danger">
              <Panel.Heading>
                <Panel.Title componentClass="h3">Error</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
              {this.state.currentProduct.errorMessage}
              </Panel.Body>
            </Panel> : null}
              <div />
              <div />
            </div>
            <div className="flex-container flex-container-space-evenly">
              <Panel bsStyle="primary" className="panel-80-percent">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Request Info</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <table>
                    <colgroup>
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      {productInfoMap}
                    </tbody>
                  </table>
                </Panel.Body>
              </Panel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.handleCancelModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
