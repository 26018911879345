import React, { Component } from "react";
import { Auth } from "aws-amplify";
import {
  FormGroup,
  FormControl,
  Tabs,
  Tab,
  TabContent,
  ControlLabel,
  Glyphicon,
  HelpBlock,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AlertModal from "../components/AlertModal";
import "../stylesheets/App.css";
import {
  updateUser,
} from "../libs/db-lib";
import { fieldChangeCase } from "../libs/utils";
import { navbarSetup, resetUserData, passwordValidate, nameValidate } from "../libs/utils";
import AutoAuthNavBar from "./AutoAuthNavBar";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      emailChanged: false,
      alertMessage: '',
      showModal: false,
      showAddUsersModal: false,
      showAddUsersCompleteModal: false,
      oldPassword: '',
      newPassword: '',
      verifyPassword: '',
      shop: '',
      username: '',
      usertype: '',
      shopPaymentInfo: {},
      paymentInfo: null,
      emailVerificationCodeSent: false,
      user: props.user,
      numToAdd: 1,
      isLoading: false,
      isLoadingIncreaseMaxUsers: false,
      paymentAmount: 0,
      showReceiptModal: false,
      currentReceipt: {},
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      await resetUserData(this);
      this.props.updateUserInfo(this.state.user);
      this.setState({
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName,
        originalEmail: this.state.user.email,
        email: this.state.user.email,
        emailVerified: this.state.user.emailVerified,
        username: this.state.user.username,
        usertype: this.state.user.userType,
      });
     } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true
      });
    }
  }

  translateStatus(status) {
    if (status === 'submitted_for_settlement') {
      return "Submitted for Settlement";
    } else if (status === 'settled') {
      return "Settled";
    } else {
      return status;
    }
  }

  validateForm() {

    let namesValid = nameValidate(this.state.firstName) && nameValidate(this.state.lastName);

    return (
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      namesValid &&
      (this.state.user.firstName !== this.state.firstName ||
      this.state.user.lastName !== this.state.lastName)
    );
  }

  nameHasChanged() {
    return (
      this.state.shop.length > 0 &&
      this.state.shop !== this.state.user.shop
    );
  }

  validatePasswordForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      passwordValidate(this.state.newPassword) &&
      this.state.newPassword === this.state.verifyPassword
    );
  }

  handleChange = event => {
    let newValue = event.target.value;
    if (event.target.id === 'email') {
      fieldChangeCase(this, event.target, "lower");
      newValue = newValue.toLowerCase();
      if (newValue !== this.state.originalEmail) {
        this.setState({emailChanged: true});
      } else {
        this.setState({emailChanged: false});
      }
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  handleNumToAddChange = event => {
    let numToAdd = parseInt(event.target.value, 10);
    if (numToAdd !== this.state.numToAdd) {
      this.setState({numToAdd: numToAdd});
    }
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleValidateEmail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.setState({ emailVerificationCodeSent: true });
      }).catch((e) => {
        this.setState({
          alertMessage: "Error sending Verification Code: "+ e.message,
          showModal: true,
          isLoading: false
        });
      });
  }

  handleSubmitConfirmationCode = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirmationCode)
      .then(() => {
        let newUser = this.state.user;
        newUser.emailVerified = true;
        this.setState({
          emailVerificationCodeSent: false,
          emailVerified: true,
          user: newUser,
          alertMessage: "Email Address has been verified",
          showModal: true,
          isLoading: false
        });
      }).catch((e) => {
        this.setState({
          alertMessage: "Invalid Verification Code",
          showModal: true,
          isLoading: false
        });
      });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    console.log("state: ", this.state);

    let result = await updateUser(this.state.user.user.userID, this.state.user.authProvider, this.state.firstName, this.state.lastName, this.state.email);
    if (result.error) {
      this.setState({
        alertMessage: result.error,
        showModal: true,
        isLoading: false,
        email: this.state.originalEmail,
        emailChanged: false
      });
    } else {
      let newUser = this.state.user;
      newUser.firstName = this.state.firstName;
      newUser.lastName = this.state.lastName;
      newUser.email = this.state.email;
      if (this.state.emailChanged) {
        this.setState({
          emailVerificationCodeSent: true,
          emailVerified: false,
          emailChanged: false,
          originalEmail: newUser.email
        });
      }
      this.setState({ user: newUser});
      this.setState({
        alertMessage: "Profile Updated!",
        showModal: true,
        isLoading: false
      });
    }
  }

  handlePasswordSubmit = async event => {
    event.preventDefault();

    if (this.state.oldPassword === this.state.newPassword) {
      this.setState({
        alertMessage: "New password cannot be the same as the old password.",
        showModal: true,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: true });
      try {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
        this.setState({
          alertMessage: "Password Updated!",
          showModal: true,
          isLoading: false,
          oldPassword: '',
          newPassword: '',
          verifyPassword: ''
        });
      } catch (e) {
        let msg = 'Old password is not correct.';
        if (e.message !== 'Incorrect username or password.') {
          msg = e.message;
        }
        this.setState({
          alertMessage: msg,
          showModal: true,
          isLoading: false
        });
      }
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    let currentTab = this.props.location &&
                     this.props.location.state &&
                     this.props.location.state.tab &&
                     this.props.location.state.tab !== undefined ? this.props.location.state.tab : "profile";

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <div className="panel-frame">
          <Tabs defaultActiveKey={currentTab} id="user-profile-tabs">
            <Tab key="1" eventKey="profile" title="Profile">
              <TabContent>
                { this.state.user && (typeof this.state.originalEmail !== 'undefined') ?
                <div className="col-sm-12 text-center">
                  <br />
                  <form className="white-container" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="row margin-bottom-15">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">User Type:</ControlLabel>
                      <ControlLabel className="col-md-6 col-sm-7 left-align">{this.state.usertype}</ControlLabel>
                    </div>
                    {this.state.user.authProvider === "COGNITO" ?
                    <div className="row">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">First Name:</ControlLabel>
                      <FormGroup controlId="firstName" bsSize="large" className="col-md-6 col-sm-6 col-xs-12">
                        <FormControl
                          autoFocus
                          maxLength="50"
                          className={!nameValidate(this.state.firstName)?'error':''}
                          placeholder="First Name"
                          type="text"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                        />
                        { !nameValidate(this.state.firstName) ?
                          <HelpBlock className="error">First name cannot be blank and cannot contain special characters</HelpBlock>
                          : ''
                        }
                      </FormGroup>
                    </div>
                    :
                    <div className="row margin-bottom-15">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">First Name:</ControlLabel>
                      <ControlLabel className="col-md-6 col-sm-7 left-align">{this.state.firstName}</ControlLabel>
                    </div>
                    }
                    {this.state.user.authProvider === "COGNITO" ?
                    <div className="row">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">Last Name:</ControlLabel>
                      <FormGroup controlId="lastName" bsSize="large" className="col-md-6 col-sm-6 col-xs-12">
                        <FormControl
                          autoFocus
                          maxLength="50"
                          className={!nameValidate(this.state.lastName)?'error':''}
                          type="text"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleChange}
                        />
                        { !nameValidate(this.state.lastName) ?
                          <HelpBlock className="error">Last name cannot be blank and cannot contain special characters</HelpBlock>
                          : ''
                        }
                      </FormGroup>
                    </div>
                    :
                    <div className="row margin-bottom-15">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">Last Name:</ControlLabel>
                      <ControlLabel className="col-md-6 col-sm-7 left-align">{this.state.lastName}</ControlLabel>
                    </div>
                    }
                    <div className="row margin-bottom-15">
                      <ControlLabel className="col-md-2 col-sm-3 right-align">Email Address:</ControlLabel>
                      <ControlLabel className="col-md-6 col-sm-7 left-align">{this.state.email}</ControlLabel>
                    </div>

                    <div className="row">
                      <ControlLabel className="col-xs-2"></ControlLabel>

                      <div className="btn-container">
                        <LoaderButton
                          id="submit-button"
                          className="red-button"
                          block
                          bsSize="large"
                          disabled={!this.validateForm()}
                          isLoading={this.state.isLoading}
                          type="submit"
                          text="Update"
                          loadingText="Updating…"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            {this.state.user.authProvider === "COGNITO" ?
            <Tab key="2" eventKey="password" title="Password" disabled={!this.state.emailVerified?true:false}>
              <TabContent>
                { this.state.user && this.state.emailVerified === true ?
                <form onSubmit={this.handlePasswordSubmit}>
                  <br />
                  <div className="col-sm-7 text-center">
                    <div className="row">
                      <ControlLabel className="col-sm-4 right-align">Old Password:</ControlLabel>
                      <FormGroup controlId="oldPassword" bsSize="large" className="col-sm-6">
                        <FormControl
                          autoFocus
                          maxLength="50"
                          type="password"
                          placeholder="Old Password"
                          value={this.state.oldPassword}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="row">
                      <ControlLabel className="col-sm-4 right-align">New Password:</ControlLabel>
                      <FormGroup controlId="newPassword" bsSize="large" className="col-sm-6">
                        <FormControl
                          autoFocus
                          maxLength="50"
                          className={this.state.newPassword.length > 0 && !passwordValidate(this.state.newPassword) ?'error':''}
                          type="password"
                          placeholder="New Password"
                          value={this.state.newPassword}
                          onChange={this.handleChange}
                        />
                        { this.state.newPassword.length > 0 && !passwordValidate(this.state.newPassword) ?
                          <HelpBlock className="error">Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter</HelpBlock>
                          : ''
                        }
                      </FormGroup>
                    </div>
                    <div className="row">
                      <ControlLabel className="col-sm-4 right-align">Verify New Password:</ControlLabel>
                      <FormGroup controlId="verifyPassword" bsSize="large" className="col-sm-6">
                        <FormControl
                          autoFocus
                          maxLength="50"
                          className={this.state.verifyPassword.length > 0 && this.state.newPassword !== this.state.verifyPassword?'error':''}
                          type="password"
                          placeholder="Verify Password"
                          value={this.state.verifyPassword}
                          onChange={this.handleChange}
                        />
                        { this.state.verifyPassword.length > 0 && this.state.newPassword !== this.state.verifyPassword ?
                          <HelpBlock className="error">Password and Confirm Password do not match</HelpBlock>
                          : ''
                        }
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <div className="col-sm-12">
                        <LoaderButton
                          block
                          className="btn-container red-button"
                          bsSize="large"
                          disabled={!this.validatePasswordForm()}
                          type="submit"
                          isLoading={this.state.isLoading}
                          text="Change Password"
                          loadingText="Changing…"
                        />
                      </div>
                    </FormGroup>
                  </div>
                </form>
                : '' }
              </TabContent>
            </Tab>
            : '' }
          </Tabs>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }
}
