export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "PROD",
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://zjf1xje6a3.execute-api.eu-central-1.amazonaws.com/dev",
    APIURL: "https://nuaw0t6c87.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_fxN10LPTT",
    APP_CLIENT_ID: "5gpfb519bt4jvr2ij5j9a75g9e",
    DOMAIN: "https://aa-huntington-prod.auth.eu-central-1.amazoncognito.com",
    IDENTITY_POOL_ID: "eu-central-1:b8b8b0e6-15fa-4728-8c57-b44fec851658"
  }
};
