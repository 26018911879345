import React, { Component } from 'react';
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
} from 'react-bootstrap';
import AutoAuthNavBar from '../AutoAuthNavBar';
import AutoAuthBreadcrumbs from '../../components/AutoAuthBreadcrumbs';
import { navbarSetup, resetUserData, nameValidate } from '../../libs/utils';
// import { Button } from 'react-bootstrap';
import { addDLMEnrollment, verifyDLMEnrollment } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import AlertModal from '../../components/AlertModal';
import '../../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageDLMEnrollment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      user: props.user,
      filter: '',
      authProvider: '',
      enrollmentCode: '',
      enrollmentCodeFieldActivate: false,
      enrollmentVerified: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await verifyDLMEnrollment();
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          enrollmentVerified: result.isEnrolled,
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    let codeValid = nameValidate(this.state.enrollmentCode);
    return codeValid;
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });

    try {
      let result = await addDLMEnrollment(this.state.enrollmentCode);
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          alertMessage: 'DLM Enrollment successfully added!',
          showModal: true,
        });
        let result = await verifyDLMEnrollment();
        console.log(result);
        if (result.errorDesc) {
          throw new Error(result.errorDesc);
        } else {
          this.setState({
            enrollmentVerified: result[0].verified,
          });
        }
      }
    } catch (e) {
      this.setState({
        showModal: true,
        alertMessage: e,
      });
    }

    this.setState({
      isLoading: false,
    });
  };

  render() {
    const navbarData = navbarSetup(this.state);

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}
        ></AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="manage-dlm-enrollments-tab">
            <Tab key="1" eventKey={1} title="DLM Enrollment">
              <TabContent>
                {this.state.enrollmentVerified === true ||
                this.state.enrollmentVerified === false ? (
                  <div
                    className={
                      this.state.windowWidth > 400
                        ? 'col-lg-10 col-md-11'
                        : 'col-lg-10 col-md-11 no-left-padding'
                    }
                  >
                    <br />
                    <div>
                      DLM Enrollment Verified:{' '}
                      {this.state.enrollmentVerified ? (
                        <b>TRUE</b>
                      ) : (
                        <b>FALSE</b>
                      )}
                    </div>
                    <div>Date Verified: {'00/00/0000'}</div>
                  </div>
                ) : (
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                )}
              </TabContent>
            </Tab>
            <Tab
              key="2"
              eventKey={2}
              title={
                this.state.enrollmentVerified
                  ? 'Change DLM Enrollment'
                  : 'Add DLM Enrollment'
              }
            >
              <TabContent>
                <div className="col-sm-4">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <br />
                      <h3 className="text-center">
                        Submit a DLM Enrollment Code
                      </h3>
                    </FormGroup>
                    <FormGroup controlId="enrollmentCode" bsSize="large">
                      <ControlLabel
                        className={
                          this.state.enrollmentCodeFieldActivate ||
                          this.state.enrollmentCode.length > 0
                            ? 'float-label field-active'
                            : 'float-label'
                        }
                      >
                        Enrollment Code
                      </ControlLabel>
                      <FormControl
                        className={
                          !nameValidate(this.state.enrollmentCode)
                            ? 'error'
                            : ''
                        }
                        maxLength="50"
                        type="text"
                        value={this.state.enrollmentCode}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      {!nameValidate(this.state.enrollmentCode) ? (
                        <HelpBlock className="error">
                          Enrollment code cannot be blank and cannot contain
                          special characters
                        </HelpBlock>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <div className="btn-container">
                      <LoaderButton
                        id="submit-button"
                        className="red-button"
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Submitting..."
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6"></div>
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel}
        ></AlertModal>

        {/* <Modal show={this.state.showGeneratedCodeModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Odyssey Enrollment Code Generated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your Odyssey Enrollment code is: <b>{this.state.enrollmentCode}</b>. Please copy it somewhere and use it to enroll your Odyssey Client with AutoAuth.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancelModal.bind(this)}>OK</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Enrollment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the enrollment: {this.state.enrollmentNameToDelete}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleConfirmModal.bind(this, this.state.enrollmentToDelete)}>Confirm</Button>
            <Button onClick={this.handleCancelModal.bind(this)}>Cancel</Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}
