import React, { Component } from 'react';
import {
  Button,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
} from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import CustomDatePicker from '../components/CustomDatePicker';
import moment from 'moment';
import AutoAuthNavBar from './AutoAuthNavBar';
import AutoAuthBreadcrumbs from '../components/AutoAuthBreadcrumbs';
import { navbarSetup, resetUserData } from '../libs/utils';
import { getAuditLog } from '../libs/db-lib';
import AlertModal from '../components/AlertModal';
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      user: props.user,
      filter: '',
      reports: null,
      reportsList: [],
      startDate: moment().subtract(1, 'day'),
      endDate: moment(),
      isLoading: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getAuditLog(this.state.startDate, this.state.endDate);
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          reports: result.logEntries || [],
          reportsList: result.logEntries ? result.logEntries : [],
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(
          /[.*+\-?^${}()|[\]\\]/g,
          '\\$&'
        );
        const filterArr = processedStr
          ? processedStr.split(' ').filter((f) => f !== '')
          : [];
        const filterStr = filterArr.reduce((f1, f2) => f1 + '|' + f2);
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const reportsList = this.state.reportsList.filter((u) => {
          return (
            regexStr.test(u.userID) ||
            regexStr.test(u.actionMessage) ||
            regexStr.test(u.actionCode) ||
            regexStr.test(u.actionDate)
          );
        });
        this.setState({
          reportsList: reportsList ? reportsList : [],
        });
      } else {
        this.setState({
          reportsList: this.state.reports,
        });
      }
    }
  };

  handleRangeChange = async (value) => {
    this.setState({
      startDate: value.start,
      endDate: value.end
    }, () => {
      this.setState({
        isLoading: true
      })
    });

    try {
      let result = await getAuditLog(value.start, value.end);
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          reports: result.logEntries || [],
          reportsList: result.logEntries ? result.logEntries : [],
          isLoading: false
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
  };

  handleCancelModal(e) {
    e.preventDefault();
    this.setState({
      showModal: false,
    });
  }

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  buildCsv = () => {
    const header = ["Action Date", "User ID", "Action", "Details"];

            
    let csvContent = header + "\r\n";

    this.state.reportsList.forEach((report) => {
      let sortedKeys = ['actionDate', 'userID', 'actionCode', 'actionMessage']
      let rowArray = [];

      for (let key of sortedKeys) {
        rowArray.push(report[key] ? report[key].replace(/,/g, '-') : "N/A");
      }

      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent)
    );
    element.setAttribute('download', `${this.state.startDate.format("YYYY-MM-DD")} to ${this.state.endDate.format("YYYY-MM-DD")}-Report.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push({
      Header: 'Action Date',
      id: 'actionDate',
      accessor: 'actionDate',
      minWidth: 80,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'User ID',
      id: 'userID',
      accessor: 'userID',
      minWidth: 100,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Action',
      id: 'actionCode',
      accessor: 'actionCode',
      minWidth: 150,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Details',
      id: 'actionMessage',
      accessor: 'actionMessage',
      minWidth: 100,
      className: 'text-center',
    });

    let reports =
      this.state && this.state.reportsList && this.state.reportsList.length > 0
        ? this.state.reportsList.map((report) => {
            return {
              actionDate: report.actionDate,
              userID: report.userID,
              actionCode: report.actionCode,
              actionMessage: report.actionMessage,
            };
          })
        : [];
    let loadingText = <p>
      Loading reports from <strong>{this.state.startDate.format("YYYY-MM-DD")}</strong> to <strong>{this.state.endDate.format("YYYY-MM-DD")}</strong>
    </p>

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}
        ></AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="reports-tab">
            <Tab key="1" eventKey={1} title="Reports">
              <TabContent>
                {this.state.reports && this.state.reportsList.length >= 0 ? (
                  <div
                    className={
                      this.state.windowWidth > 400
                        ? 'col-lg-12 col-md-11'
                        : 'col-lg-10 col-md-11 no-left-padding'
                    }
                  >
                    <br />
                    <div className="col-sm-9 center text-bold">Date Range:</div>
                    <br />
                    <br />
                    <div className="flex-container align-flex-start col-sm-9 center">
                      <CustomDatePicker
                        value={{
                          start: this.state.startDate,
                          end: this.state.endDate,
                          name: 'Last 30 Days',
                        }}
                        onChange={this.handleRangeChange.bind(this)}
                      />
                      <Button
                        bsStyle="primary"
                        onClick={this.buildCsv}
                        className="spacer15"
                      >
                        <Icon name="file alternate" />
                        Export CSV
                      </Button>
                    </div>

                    <br />
                    <div className="auto-auth-table col-sm-12">
                      <FormGroup controlId="filter" bsSize="large">
                        <ControlLabel
                          className={
                            this.state.filterFieldActivate ||
                            this.state.filter.length > 0
                              ? 'float-label field-active'
                              : 'float-label'
                          }
                        >
                          Filter
                        </ControlLabel>
                        <FormControl
                          maxLength="50"
                          type="text"
                          value={this.state.filter}
                          onChange={this.handleChange.bind(this)}
                          onFocus={this.activateField.bind(this)}
                          onBlur={this.disableField.bind(this)}
                        />
                      </FormGroup>
                      <ReactTable
                        columns={columnDefs}
                        data={reports}
                        noDataText={'No Reports Found'}
                        className="-highlight"
                        defaultPageSize={10}
                        defaultSorted={[
                          {
                            id: 'userID',
                            desc: false,
                          },
                        ]}
                        loading={this.state.isLoading}
                        loadingText={loadingText}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                )}
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel}
        ></AlertModal>
      </div>
    );
  }
}
