import React, { Component } from "react";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "../stylesheets/NavBar.css";
import config from "../config";

export default class AutoAuthNavBar extends Component {

  handleLogout = async event => {

    const redirectStr = config.AUTH_DIAG_ENVIRONMENT === 'DEV' ? 'http://localhost:4000/Home' : "https://" + window.location.hostname + "/Home";

    const paramStr =
      "response_type=code&"+
      "scope=aws.cognito.signin.user.admin+email+openid+phone+profile&"+
      "client_id="+config.cognito.APP_CLIENT_ID+"&"+
      "redirect_uri="+redirectStr;

    window.location.assign(config.cognito.DOMAIN + "/logout?"+paramStr);
  }

  handleShopChange(event) {
    let newShop = event.target.value;
    this.setState({
      currentShop: newShop
    })
    if (this.props.handleShopChange) {
      this.props.handleShopChange(newShop);
    }
  }

  render() {
    const isEnrollment = this.props.user.userType === 'ENROLLMENT';
    const isManagerAdmin = this.props.user.userType === 'ADMIN' || this.props.user.userType === 'MANAGER';

    const windowWidth = window.innerWidth;

    return (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to="/">
              <img className="brand-image" alt="AutoAuth" src='img/AutoAuth-transparent-TM.png' />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className="navbar logged-in">
          <div>
            { !isEnrollment ?
            <ul className="nav navbar-nav">
              <li>
                <NavLink className={(this.props.pathname === "/home")?"nav-item selected":"nav-item"} to="/home">
                  Home
                </NavLink>
              </li>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <li>
                <NavLink className={(this.props.pathname === "/userProfile")?"nav-item selected":"nav-item"} to="/userProfile">
                  User Profile
                </NavLink>
              </li>
              { this.props.user && (isManagerAdmin) ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <li>
                  <NavLink className={(this.props.pathname === "/manageUsers")?"nav-item selected":"nav-item"} to="/manageUsers">
                    Manage Users
                  </NavLink>
                </li>
               : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <li>
                  <NavLink className={(this.props.pathname === "/manageProfiles")?"nav-item selected":"nav-item"} to="/manageProfiles">
                    Manage Profiles
                  </NavLink>
                </li>
               : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
             { this.props.user && this.props.user.userType === 'ADMIN' ?
                <li>
                  <NavLink className={(this.props.pathname === "/manageProducts")?"nav-item selected":"nav-item"} to="/manageProducts">
                    Manage Products
                  </NavLink>
                </li>
               : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && (isManagerAdmin) ?
                <li>
                  <NavLink className={(this.props.pathname === "/certRequests")?"nav-item selected":"nav-item"} to="/certRequests">
                    Cert Requests
                  </NavLink>
                </li>
              : ''}
              { this.props.user && (isManagerAdmin) ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && (isManagerAdmin) ?
                <li>
                  <NavLink className={(this.props.pathname === "/signRequests")?"nav-item selected":"nav-item"} to="/signRequests">
                    Sign Requests
                  </NavLink>
                </li>
              : ''}
              { this.props.user && (isManagerAdmin) ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && (isManagerAdmin) ?
                <li>
                  <NavLink className={(this.props.pathname === "/reports")?"nav-item selected":"nav-item"} to="/reports">
                    Reports
                  </NavLink>
                </li>
              : ''}

            </ul>
            :
            <ul className="nav navbar-nav">
              <li>
                <NavLink className={(this.props.pathname === "/home" || this.props.pathname === "/dashboard")?"nav-item selected":"nav-item"} to="/home">
                  Home
                </NavLink>
              </li>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <li>
                <NavLink className={(this.props.pathname === "/manageClientEnrollments")?"nav-item selected":"nav-item"} to="/manageClientEnrollments">
                  Manage Client Enrollments
                </NavLink>
              </li>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <li>
                <NavLink className={(this.props.pathname === "/manageDLMEnrollment")?"nav-item selected":"nav-item"} to="/manageDLMEnrollment">
                  Manage DLM Enrollment
                </NavLink>
              </li>
            </ul>
          }
            <Nav pullRight>
              <NavItem className="header-text">{this.props.name}</NavItem>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <NavDropdown className={((this.props.pathname === "/#/FAQ" || this.props.pathname === "/#/Support" || this.props.pathname === "/SystemInfo") && windowWidth > 440)?"nav-item header-help selected":"nav-item header-help"} title={windowWidth > 768 ? <Icon title="Get Support" name="help circle" /> : "Support"} id="support-dropdown">
                <li>
                  <NavLink to="/SystemInfo">System Info</NavLink>
                </li>
              </NavDropdown>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <NavItem onClick={this.handleLogout}>Logout</NavItem>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
