import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import ResendConfirmCode from "./containers/ResendConfirmCode";
import Signup from "./containers/Signup";
import UserCreate from "./containers/UserCreate";
import UserProfile from "./containers/UserProfile";
import ManageUsers from "./containers/ManageUsers";
import EditUser from "./containers/EditUser";
import ManageProfiles from "./containers/ManageProfiles";
import ManageProducts from "./containers/ManageProducts";
import Dashboard from "./containers/Dashboard";
import Home from "./containers/Home";
import Support from "./containers/Support";
import SystemInfo from "./containers/SystemInfo";
import UserFAQ from "./containers/UserFAQ";
import FAQ from "./containers/FAQ";
import ContactUs from "./containers/ContactUs";
import AboutUs from "./containers/AboutUs";
import Welcome from "./containers/Welcome";
import NotFound from "./containers/NotFound";
import ManageClientEnrollments from "./containers/enrollments/ManageClientEnrollments";
import ManageDLMEnrollment from "./containers/enrollments/ManageDLMEnrollment";
import CertRequests from './containers/CertRequests';
import SignRequests from './containers/SignRequests';
import Reports from './containers/Reports';
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/Home" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/resetPassword" exact component={ResetPassword} props={childProps} />
    <UnauthenticatedRoute path="/resendConfirmCode" exact component={ResendConfirmCode} props={childProps} />
    <UnauthenticatedRoute path="/requestAccount" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/FAQ" exact component={FAQ} props={childProps} />
    <UnauthenticatedRoute path="/ContactUs" exact component={ContactUs} props={childProps} />
    <UnauthenticatedRoute path="/AboutUs" exact component={AboutUs} props={childProps} />
    <AuthenticatedRoute path="/userCreate" exact component={UserCreate} props={childProps} />
    <AuthenticatedRoute path="/userProfile" exact component={UserProfile} props={childProps} />
    <AuthenticatedRoute path="/manageUsers" exact component={ManageUsers} props={childProps} />
    <AuthenticatedRoute path="/manageUsers/editUser" exact component={EditUser} props={childProps} />
    <AuthenticatedRoute path="/manageProfiles" exact component={ManageProfiles} props={childProps} />
    <AuthenticatedRoute path="/manageProducts" exact component={ManageProducts} props={childProps} />
    <AuthenticatedRoute path="/welcome" exact component={Welcome} props={childProps} />
    <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
    <AuthenticatedRoute path="/Support" exact component={Support} props={childProps} />
    <AuthenticatedRoute path="/SystemInfo" exact component={SystemInfo} props={childProps} />
    <AuthenticatedRoute path="/UserFAQ" exact component={UserFAQ} props={childProps} />
    <AuthenticatedRoute path="/manageClientEnrollments" exact component={ManageClientEnrollments} props={childProps} />
    <AuthenticatedRoute path="/manageDLMEnrollment" exact component={ManageDLMEnrollment} props={childProps} />
    <AuthenticatedRoute path="/certRequests" exact component={CertRequests} props={childProps} />
    <AuthenticatedRoute path="/signRequests" exact component={SignRequests} props={childProps} />
    <AuthenticatedRoute path="/reports" exact component={Reports} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

  export const breadcrumbNameMap = {
    "resetPassword": { label: "Reset Password", link: true },
    "resendConfirmCode": { label: "Resend Confirm Code", link: true },
    "register": { label: "Register", link: true },
    "requestAccount": { label: "Request Account", link: true },
    "userCreate": { label: "User Create", link: true },
    "userProfile": { label: "User Profile", link: true },
    "manageUsers": { label: "Manage Users", link: true },
    "editUser": { label: "Edit User", link: true },
    "manageProfiles": { label: "Manage Profiles", link: true },
    "manageProducts": { label: "Manage Products", link: true },
    "manageClientEnrollments": { label: "Manage Client Enrollments", link: true },
    "manageDLMEnrollment": { label: "Manage DLM Enrollment", link: true },
    "certRequests": { label: "Cert Requests", link: true },
    "signRequests": { label: "Sign Requests", link: true },
    "reports": { label: "Reports", link: true },
    "Home": { label: "Home", link: true },
    "Support": { label: "Support", link: true },
  }
