import React, { Component } from "react";
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Modal
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import { navbarSetup,
         resetUserData,
         nameValidate } from "../libs/utils";
import { Button } from 'react-bootstrap';
import { addProduct, getProducts, updateProduct, deleteProduct } from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import AlertModal from "../components/AlertModal";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showEditModal: false,
      showDeleteModal: false,
      user: props.user,
      filter: "",
      newProductLabel: '',
      newProductID: '',
      currentProduct: {}, // Current product being processed (edit or delete)
      products: null,
      productList: [],
      isLoading: false,
      newProductIdFieldActivate: '',
      newProductLableFieldActivate: '',
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getProducts();

      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          products: result.productList || [],
          productList: result.productList ? result.productList : [],
          maxProducts: result.maxProducts,
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  // Sets verification state to only activate error checking when user types in field
  activateFieldVerify(field) {
    this.setState({ [field + 'Verify']: true });
  }

  handleShowEdit = (product) => {
    this.setState({
      showEditModal: true,
      currentProduct: product,
      newProductLabel: product.productLabel
    })
  }

  handleEdit = async (currentProduct, type) => {
    this.setState({
      isLoading: true
    })
    if (type === "ENABLE" || type === "DISABLE") {
      this.setState({
        showModal: true,
        alertMessage: "Updating product...",
      })
    }
    try {
      // Handle edit product
      const { productID, productLabel, productState } = currentProduct;

      let result = await updateProduct(productID, this.state.newProductLabel || productLabel, productState);
      if (result.errorDesc) {
        throw new Error(result.errorDesc)
      } else {
        this.setState({
          isLoading: false,
          showModal: true,
          showEditModal: false,
          alertMessage: "Product successfully updated!"
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        newProductLabel: '',
        alertMessage: e,
        showModal: true,
        isLoading: false
      });
    }
    let products = this.state.productList;
    products = products.map((product) => {
      if (product.productID === currentProduct.productID) {
        product.productLabel = this.state.newProductLabel || currentProduct.productLabel;
        product.productState = currentProduct.productState;
      }
      return product
    });
    this.setState({
      productList: products,
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    this.activateFieldVerify(event.target.id);
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        const filterArr = processedStr ? processedStr.split(' ').filter((f) => f !== '') : [];
        const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const productList = this.state.productList.filter((u) => {
          return (
            regexStr.test(u.productID) ||
            regexStr.test(u.productLabel) ||
            regexStr.test(u.productState)
          );
        })
        this.setState({
          productList: productList ? productList : []
        });
      } else {
        this.setState({
          productList: this.state.products
        });
      }
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }

  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleDeleteButton = async (product) => {
    this.setState(
      {
        showDeleteModal: true,
        currentProduct: product
      }
    );
  }

  handleConfirmModal = async () => {
    this.setState({
      isLoading: true
    })
    try {
      // Handle delete product
      let result = await deleteProduct(this.state.currentProduct.productID);
      if (result.errorDesc) {
        throw new Error(result.errorDesc)
      } else {
        this.setState({
          showModal: true,
          alertMessage: "Product successfully deleted!",
          isLoading: false
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        currentProduct: {},
        showModal: true,
        isLoading: false
      });
    }

    let products = this.state.productList;
    products = products.filter((product) => {
      return product.productID !== this.state.currentProduct.productID
    })

    this.setState({ showDeleteModal: false,
                    isLoading: false,
                    currentProduct: {},
                    productList: products });
  }

  validateForm() {
    let labelValid = nameValidate(this.state.newProductLabel);

    return labelValid;
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
      showEditModal: false,
      showDeleteModal: false,
      currentProduct: {},
      newProductLabel: ''
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true
    })

    try {
      let result = await addProduct(this.state.newProductID, this.state.newProductLabel);

      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          isLoading: false,
          showModal: true,
          newProductID: '',
          newProductLabel: '',
          newProductIDVerify: false,
          newProductLabelVerify: false,
          alertMessage: `Product ${this.state.newProductLabel} successfully added!`
        }, async () => {
          let results = await getProducts();

          this.setState({
            products: results.productList,
            productList: results.productList ? results.productList : [],
          });
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        newProductID: '',
        newProductLabel: '',
        showModal: true,
        isLoading: false
      });
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push(
      { Header: "Product ID",
        id: "productID",
        accessor: "productID",
        minWidth: 120,
        className: "text-center",
      }
    );
    columnDefs.push(
      { Header: "Label",
        id: "productLabel",
        accessor: "productLabel",
        minWidth: 105,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "State",
        id: "productState",
        accessor: "productState",
        minWidth: 105,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "Added On",
        id: "addedOn",
        accessor: "createdOn",
        minWidth: 100,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "Added By",
        id: "addedBy",
        accessor: "createdBy",
        minWidth: 100,
        className: "text-center"
      }
    );

    columnDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "productActions",
        accessor: "productID",
        minWidth: 100,
        maxWidth: 100,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <ButtonGroup>
                <div>
                    <Button bsStyle="link" href="#" onClick={() => this.handleShowEdit(row.original)}><Icon title="Edit Product" name="edit outline" /></Button>

                  {row.original.productState === 'ACTIVE' ?
                    <Button bsStyle="link" href="#" onClick={() => this.handleEdit({...row.original, productState: 'INACTIVE'}, "DISABLE")}><Icon title="Disable Product" name="ban" /></Button>
                    :
                    <Button bsStyle="link" href="#" onClick={() => this.handleEdit({...row.original, productState: 'ACTIVE'}, "ENABLE")}><Icon title="Enable Product" name="check circle outline" /></Button>
                  }

                  <Button bsStyle="link" href="#" onClick={() => this.handleDeleteButton(row.original)}><Icon title="Delete Product" name="times circle outline" /></Button>
                </div>
              </ButtonGroup>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let products = this.state && this.state.productList && this.state.productList.length > 0 ?
      this.state.productList.map((product) => {
        return {
          productID: product.productID,
          productLabel: product.productLabel,
          productState: product.productState,
          createdOn: product.createdOn,
          createdBy: product.createdBy
        }
      })
    : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="manage-products-tab">
            <Tab key="1" eventKey={1} title="Products">
              <TabContent>
                { this.state.products && this.state.products.length >= 0 ?
                <div className={this.state.windowWidth > 400 ? "col-lg-12 col-md-11" : "col-lg-10 col-md-11 no-left-padding"} >
                  <br />
                  <div className={'spacer50'}>
                    {this.state?.products?.length + ' of ' + this.state?.maxProducts + ' Max Products'}
                  </div>
                  <br />
                  <div className="auto-auth-table col-sm-12">
                    <FormGroup controlId="filter" bsSize="large">
                      <ControlLabel className={this.state.filterFieldActivate || this.state.filter.length > 0 ? "float-label field-active" : "float-label"}>Filter</ControlLabel>
                      <FormControl
                        maxLength="50"
                        type="text"
                        value={this.state.filter}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                    </FormGroup>
                    <ReactTable
                      columns={columnDefs}
                      data={products}
                      noDataText={"No Products Found"}
                      className="-highlight"
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: "productLabel",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            <Tab key="2" eventKey={2} title="Add Product">
              <TabContent>
                <div className="col-sm-4">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <br />
                      <h3 className="text-center">Add a Product</h3>
                    </FormGroup>
                    <FormGroup controlId="newProductLabel" bsSize="large">
                      <ControlLabel className={this.state.newProductLabelFieldActivate || this.state.newProductLabel.length > 0 ? "float-label field-active" : "float-label"}>Product Label</ControlLabel>
                      <FormControl
                        className={ this.state.newProductLabelVerify && !nameValidate(this.state.newProductLabel)?'error':'' }
                        maxLength="50"
                        type="text"
                        value={this.state.newProductLabel}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.newProductLabelVerify && !nameValidate(this.state.newProductLabel) ?
                        <HelpBlock className="error">Product label cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="newProductID" bsSize="large">
                      <ControlLabel className={this.state.newProductIDFieldActivate || this.state.newProductID.length > 0 ? "float-label field-active" : "float-label"}>Product ID</ControlLabel>
                      <FormControl
                        className={ this.state.newProductIDVerify && !nameValidate(this.state.newProductID)?'error':'' }
                        maxLength="50"
                        type="text"
                        value={this.state.newProductID}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.newProductIDVerify && !nameValidate(this.state.newProductID) ?
                        <HelpBlock className="error">Product ID cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <div className="btn-container">
                      <LoaderButton
                        id="submit-button"
                        className="red-button"
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Add"
                        loadingText="Adding..."
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                </div>
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel} isLoading={this.state.isLoading} />

        <Modal show={this.state.showEditModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Product Label</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormGroup controlId="newProductLabel" bsSize="large">
                <ControlLabel className={this.state.productLabelFieldActivate || this.state.newProductLabel.length > 0 ? "float-label field-active" : "float-label"}>Product Label</ControlLabel>
                <FormControl
                  className={ this.state.newProductLabelVerify && !nameValidate(this.state.newProductLabel)?'error':'' }
                  maxLength="50"
                  type="text"
                  value={this.state.newProductLabel}
                  onChange={this.handleChange.bind(this)}
                  onFocus={this.activateField.bind(this)}
                  onBlur={this.disableField.bind(this)}
                />
                { this.state.newProductLabelVerify && !nameValidate(this.state.newProductLabel) && this.state.showEditModal ?
                  <HelpBlock className="error">Product label cannot be blank and cannot contain special characters</HelpBlock>
                  : ''
                }
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Updating..."
              onClick={() => this.handleEdit(this.state.currentProduct)}
            />
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the product: {this.state.currentProduct.productLabel}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Deleting..."
              onClick={() => this.handleConfirmModal()}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}