import React, { Component } from "react";
import { Auth } from "aws-amplify";
import AutoAuthNavBar from "./AutoAuthNavBar";
import { navbarSetup, resetUserData } from "../libs/utils";
import { requestToken } from "../libs/db-lib";
import ReactTable from 'react-table';
import AlertModal from "../components/AlertModal";
import {
  Glyphicon,
  Button,
  FormControl,
  FormGroup
} from "react-bootstrap";
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      isLoading: true,
      emailVerificationCodeSent: false,
    };
  }

  async componentDidMount() {
    if (this.props.code) {
      this.props.userHasAuthenticated(true);
    }
    try {
      if (!this.props.isAuthenticated) {
        const result = await requestToken(this.props.code || window.localStorage.getItem('code'));
        if (result) {
          this.setState({
            alertMessage: result.message ? result.message : result,
            showModal: true,
            isLoading: false
          });
        } else {
          await resetUserData(this);
          this.props.history.push({pathname: "/dashboard"});
        }
      } else {
        this.props.history.push({ pathname: "/dashboard"})
      }
    } catch (e) {
      console.log("Error getting User Info: ", e);
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleValidateEmail = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        this.setState({ emailVerificationCodeSent: true });
      }).catch((e) => {
        this.setState({
          alertMessage: e.message,
          showModal: true,
          isLoading: false
        });
      });
  }

  handleSubmitConfirmationCode = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', this.state.confirmationCode)
      .then(() => {
        let newUser = this.state.user;
        newUser.emailVerified = true;
        this.setState({
          emailVerificationCodeSent: false,
          user: newUser,
          alertMessage: "Email Address has been verified",
          showModal: true,
          isLoading: false
        });
      }).catch((e) => {
        this.setState({
          alertMessage: "Invalid Verification Code",
          showModal: true,
          isLoading: false
        });
      });
  }

  renderLander() {

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    const columnDefs = [];

    columnDefs.push({ Header: "Name",
                      id: "name",
                      accessor: "name",
                      minWidth: (this.state.windowWidth > 505)?250:120 }
    );
    columnDefs.push({ Header: "State",
                      accessor: "state",
                      className: "text-center",
                      minWidth: (this.state.windowWidth > 505)?120:100,
                      maxWidth: (this.state.windowWidth > 505)?120:100 }
    );

    const navbarData = navbarSetup(this.state);

    return (
      <div>
        { this.state && this.state.user ?
        <div key="menubar" className="home">
          <AutoAuthNavBar
            manufacturer={navbarData.thisIsAManufacturer}
            serviceCenter={navbarData.thisIsAServiceCenter}
            name={navbarData.name}
            user={this.state.user ? this.state.user.user : this.state.user}
            shopRole={navbarData.shopRole}
            shopState={navbarData.shopState}
            emailVerified={navbarData.emailVerified}
            userHasAuthenticated={this.props.userHasAuthenticated}
            pathname={this.props.location.pathname}>
          </AutoAuthNavBar>
          <div key="welcome" className="panel welcome">
            <div className="center">Welcome to AutoAuth!</div>
          </div>

          { this.state.user.emailVerified === false ?
          <div className="panel-frame">
            <div key="verify" className="panel notice">
              <div>
                <div key="state">Your user state is <span className="alertText">Pending</span>.  You must validate the email address <span className="alertText">{this.state.user.email}</span> before using this portal or change your email address in the User Profile tab.</div>
              </div>
              <br />
              <div className="alignCenter">
                <div className="col-sm-3">
                  <Button
                    id="validate-email"
                    className="red-button"
                    block
                    disabled={this.state.emailVerificationCodeSent}
                    type="button"
                    onClick={this.handleValidateEmail}
                  >Validate Email</Button>
                </div>
              </div>
            </div>
          </div>
            : ''
          }
          {this.state.emailVerificationCodeSent ?
            <div className="panel-frame">
              <div className="alignCenter">Email Address Validation code has been sent to: {this.state.user.email}</div>
              <div className="alignCenter">Enter code to confirm email.</div>
              <br />
              <div className="alignCenter">
                <div className="col-sm-6">
                  <FormGroup controlId="confirmationCode" bsSize="large" className="col-sm-6">
                    <FormControl
                      autoFocus
                      maxLength="20"
                      placeholder="Confirmation Code"
                      type="text"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="col-sm-6">
                    <Button
                      id="validate-email"
                      className="red-button"
                      block
                      disabled={!this.state.emailVerificationCodeSent}
                      type="button"
                      onClick={this.handleSubmitConfirmationCode}
                    >Submit Confirmation Code</Button>
                  </FormGroup>
                </div>
              </div>
            </div>
            : ''
          }
        </div>
        :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
        }
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }

  renderNotAuthenticated() {

    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): ''}
      </div>
    );
  }
}
