import React, { Component } from "react";
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Modal
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import { navbarSetup,
         resetUserData,
         nameValidate } from "../libs/utils";
import { Button } from 'react-bootstrap';
import { addProfile, getProfiles, updateProfile, deleteProfile} from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import AlertModal from "../components/AlertModal";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showEditModal: false,
      showDeleteModal: false,
      user: props.user,
      filter: "",
      newProfileLabel: '',
      newProfileID: '',
      currentProfile: {}, // Current profile being processed (edit or delete)
      profiles: null,
      profileList: [],
      isLoading: false,
      newProfileIDFieldActivate: '',
      newProfileLabelFieldActivate: '',
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getProfiles();

      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          profiles: result.profileList || [],
          profileList: result.profileList ? result.profileList : [],
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  // Sets verification state to only activate error checking when user types in field
  activateFieldVerify(field) {
    this.setState({ [field + 'Verify']: true });
  }

  handleShowEdit = (profile) => {
    this.setState({
      showEditModal: true,
      currentProfile: profile,
      newProfileLabel: profile.profileLabel
    })
  }

  handleEdit = async (currentProfile, type) => {
    this.setState({
      isLoading: true
    })
    if (type === "ENABLE" || type === "DISABLE") {
      this.setState({
        showModal: true,
        alertMessage: "Updating profile...",
      })
    }
    try {
      // Handle edit profile
      const { profileID, profileLabel, profileState } = currentProfile;

      let result = await updateProfile(profileID, this.state.newProfileLabel || profileLabel, profileState);
      if (result.errorDesc) {
        throw new Error(result.errorDesc)
      } else {
        this.setState({
          isLoading: false,
          showModal: true,
          showEditModal: false,
          alertMessage: "Profile successfully updated!"
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        newProfileLabel: '',
        alertMessage: e,
        showModal: true,
        isLoading: false
      });
    }
    let profiles = this.state.profileList;
    profiles = profiles.map((profile) => {
      if (profile.profileID === currentProfile.profileID) {
        profile.profileLabel = this.state.newProfileLabel || currentProfile.profileLabel;
        profile.profileState = currentProfile.profileState;
      }
      return profile
    });
    this.setState({
      profileList: profiles,
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    this.activateFieldVerify(event.target.id);
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        const filterArr = processedStr ? processedStr.split(' ').filter((f) => f !== '') : [];
        const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const profileList = this.state.profileList.filter((u) => {
          return (
            regexStr.test(u.profileID) ||
            regexStr.test(u.profileLabel) ||
            regexStr.test(u.profileState)
          );
        })
        this.setState({
          profileList: profileList ? profileList : []
        });
      } else {
        this.setState({
          profileList: this.state.profiles
        });
      }
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }

  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleDeleteButton = async (profile) => {
    this.setState(
      {
        showDeleteModal: true,
        currentProfile: profile
      }
    );
  }

  handleConfirmModal = async () => {
    this.setState({
      isLoading: true
    })
    try {
      // Handle delete profile
      let result = await deleteProfile(this.state.currentProfile.profileID);
      if (result.errorDesc) {
        throw new Error(result.errorDesc)
      } else {
        this.setState({
          showModal: true,
          alertMessage: "Profile successfully deleted!",
          isLoading: false
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        currentProfile: {},
        showModal: true,
        isLoading: false
      });
    }

    let profiles = this.state.profileList;
    profiles = profiles.filter((profile) => {
      return profile.profileID !== this.state.currentProfile.profileID
    })

    this.setState({ showDeleteModal: false,
                    isLoading: false,
                    currentProfile: {},
                    profileList: profiles });
  }

  validateForm() {
    let labelValid = nameValidate(this.state.newProfileLabel);

    return labelValid;
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
      showEditModal: false,
      showDeleteModal: false,
      currentProfile: {},
      newProfileLabel: ''
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true
    })

    try {
      let result = await addProfile(this.state.newProfileID, this.state.newProfileLabel);

      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          isLoading: false,
          showModal: true,
          newProfileID: '',
          newProfileLabel: '',
          newProfileIDVerify: false,
          newProfileLabelVerify: false,
          alertMessage: `Profile ${this.state.newProfileLabel} successfully added!`
        }, async () => {
          let results = await getProfiles();

          this.setState({
            profiles: results.profileList,
            profileList: results.profileList ? results.profileList : [],
          });
        })
      }
    } catch(e) {
      this.setState({
        showDeleteModal: false,
        alertMessage: e,
        newProfileID: '',
        newProfileLabel: '',
        showModal: true,
        isLoading: false
      });
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push(
      { Header: "Profile ID",
        id: "profileID",
        accessor: "profileID",
        minWidth: 120,
        className: "text-center",
      }
    );
    columnDefs.push(
      { Header: "Label",
        id: "profileLabel",
        accessor: "profileLabel",
        minWidth: 105,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "State",
        id: "profileState",
        accessor: "profileState",
        minWidth: 105,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "Added On",
        id: "addedOn",
        accessor: "createdOn",
        minWidth: 100,
        className: "text-center"
      }
    );
    columnDefs.push(
      { Header: "Added By",
        id: "addedBy",
        accessor: "createdBy",
        minWidth: 100,
        className: "text-center"
      }
    );

    columnDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "profileActions",
        accessor: "profileID",
        minWidth: 100,
        maxWidth: 100,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <ButtonGroup>
                <div>
                    <Button bsStyle="link" href="#" onClick={() => this.handleShowEdit(row.original)}><Icon title="Edit Profile" name="edit outline" /></Button>

                  {row.original.profileState === 'ACTIVE' ?
                    <Button bsStyle="link" href="#" onClick={() => this.handleEdit({...row.original, profileState: 'INACTIVE'}, "DISABLE")}><Icon title="Disable Profile" name="ban" /></Button>
                    :
                    <Button bsStyle="link" href="#" onClick={() => this.handleEdit({...row.original, profileState: 'ACTIVE'}, "ENABLE")}><Icon title="Enable Profile" name="check circle outline" /></Button>
                  }

                  <Button bsStyle="link" href="#" onClick={() => this.handleDeleteButton(row.original)}><Icon title="Delete Profile" name="times circle outline" /></Button>
                </div>
              </ButtonGroup>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let profiles = this.state && this.state.profileList && this.state.profileList.length > 0 ?
      this.state.profileList.map((profile) => {
        return {
          profileID: profile.profileID,
          profileLabel: profile.profileLabel,
          profileState: profile.profileState,
          createdOn: profile.createdOn,
          createdBy: profile.createdBy
        }
      })
    : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="manage-profiles-tab">
            <Tab key="1" eventKey={1} title="Profiles">
              <TabContent>
                { this.state.profiles && this.state.profiles.length >= 0 ?
                <div className={this.state.windowWidth > 400 ? "col-lg-12 col-md-11" : "col-lg-10 col-md-11 no-left-padding"} >
                  <br />
                  <div className="auto-auth-table col-sm-12">
                    <FormGroup controlId="filter" bsSize="large">
                      <ControlLabel className={this.state.filterFieldActivate || this.state.filter.length > 0 ? "float-label field-active" : "float-label"}>Filter</ControlLabel>
                      <FormControl
                        maxLength="50"
                        type="text"
                        value={this.state.filter}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                    </FormGroup>
                    <ReactTable
                      columns={columnDefs}
                      data={profiles}
                      noDataText={"No Profiles Found"}
                      className="-highlight"
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: "profileLabel",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            <Tab key="2" eventKey={2} title="Add Profile">
              <TabContent>
                <div className="col-sm-4">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <br />
                      <h3 className="text-center">Add an Issuance Profile</h3>
                    </FormGroup>
                    <FormGroup controlId="newProfileLabel" bsSize="large">
                      <ControlLabel className={this.state.newProfileLabelFieldActivate || this.state.newProfileLabel.length > 0 ? "float-label field-active" : "float-label"}>Profile Label</ControlLabel>
                      <FormControl
                        className={ this.state.newProfileLabelVerify && !nameValidate(this.state.newProfileLabel)?'error':'' }
                        maxLength="50"
                        type="text"
                        value={this.state.newProfileLabel}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.newProfileLabelVerify && !nameValidate(this.state.newProfileLabel) ?
                        <HelpBlock className="error">Profile label cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="newProfileID" bsSize="large">
                      <ControlLabel className={this.state.newProfileIDFieldActivate || this.state.newProfileID.length > 0 ? "float-label field-active" : "float-label"}>Profile ID</ControlLabel>
                      <FormControl
                        className={ this.state.newProfileIDVerify && !nameValidate(this.state.newProfileID)?'error':'' }
                        maxLength="50"
                        type="text"
                        value={this.state.newProfileID}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.newProfileIDVerify && !nameValidate(this.state.newProfileID) ?
                        <HelpBlock className="error">Profile ID cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <div className="btn-container">
                      <LoaderButton
                        id="submit-button"
                        className="red-button"
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Add"
                        loadingText="Adding..."
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                </div>
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel} isLoading={this.state.isLoading} />

        <Modal show={this.state.showEditModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile Label</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <FormGroup controlId="newProfileLabel" bsSize="large">
                <ControlLabel className={this.state.profileLabelFieldActivate || this.state.newProfileLabel.length > 0 ? "float-label field-active" : "float-label"}>Profile Label</ControlLabel>
                <FormControl
                  className={ this.state.newProfileLabelVerify && !nameValidate(this.state.newProfileLabel)?'error':'' }
                  maxLength="50"
                  type="text"
                  value={this.state.newProfileLabel}
                  onChange={this.handleChange.bind(this)}
                  onFocus={this.activateField.bind(this)}
                  onBlur={this.disableField.bind(this)}
                />
                { this.state.newProfileLabelVerify && !nameValidate(this.state.newProfileLabel) && this.state.showEditModal ?
                  <HelpBlock className="error">Profile label cannot be blank and cannot contain special characters</HelpBlock>
                  : ''
                }
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Updating..."
              onClick={() => this.handleEdit(this.state.currentProfile)}
            />
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteModal} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete the profile: {this.state.currentProfile.profileLabel}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <LoaderButton
              id="submit-button"
              bsStyle="primary"
              type="submit"
              isLoading={this.state.isLoading}
              text="Confirm"
              loadingText="Deleting..."
              onClick={() => this.handleConfirmModal()}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}