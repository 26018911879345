import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../stylesheets/daterangepicker.css';

import {
  FormGroup,
} from 'react-bootstrap';

export default class CustomDatePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      start: props.value.start,
      end: props.value.end,
      rangeName: "Custom",
      key: 0,
    };
  }

  componentDidMount() {
    document.getElementById((this.props.idPrefix?this.props.idPrefix:"")+"dateRange").value = this.calcRangeStr(this.props.value.start, this.props.value.end);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState({
        key: this.state.key + 1
      })
    }
  }

  handleRangeChange(range) {
    document.getElementById((this.props.idPrefix?this.props.idPrefix:"")+"dateRange").value = this.calcRangeStr(range.start, range.end);
    if (this.props.onChange) {
      this.props.onChange({
        start: range.start,
        end: range.end,
      });
    }
  }

  handlePresetChange(name, range, event) {

    if (this.props.onChange) {
      this.props.onChange({
        start: range[0](),
        end: range[1](),
      });
    }
    this.setState({'rangeName': name});
  }

  calcRangeStr(start, end) {
    return <span>
             <span className="text-bold">{start.utc().format("YYYY-MM-DD HH:mm:ss")}</span> {"-"}
             <span className="text-bold">{end.utc().format("YYYY-MM-DD HH:mm:ss")}</span> UTC
           </span>
  }

  render() {
    let label = this.props.value.start ? this.calcRangeStr(this.props.value.start, this.props.value.end) : '';

    let today = "Today";
    let yesterday = "Yesterday";
    let last30days = "Last 30 Days";
    let thisMonth = "This Month";
    let lastMonth = "Last Month";

    // The 'range' property is a 2-element array containing functions that return a particular moment in
    // time. This is done so that these moments can be lazily computed when a saved search is loaded.
    let ranges = {};
    ranges[today] = [moment.utc().startOf('day').toDate(), moment.utc().endOf('day').toDate()];
    ranges[yesterday] = [moment.utc().subtract(1, 'days').startOf('day'), moment.utc().subtract(1, 'days').endOf('day')];
    ranges[last30days] = [moment.utc().subtract(30, 'days').startOf('day'), moment.utc().endOf('day') ];
    ranges[thisMonth] = [moment.utc().startOf('month').startOf('day'), moment.utc().endOf('day')];
    ranges[lastMonth] = [moment.utc().subtract(1, 'month').startOf('month').startOf('day'), moment.utc().subtract(1, 'month').endOf('month').endOf('day')];

    const locale = {
      language: 'en',
      format: 'YYYY-MM-DD HH:mm:ss',
      separator: ' - ',
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      customRangeLabel: "Custom Range",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      daysOfWeek: [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
      ]
    }

    return (
      <div>
        <FormGroup>
          <DateRangePicker
            key={this.state.key}
            useCurrent={false}
            initialSettings={{
              startDate: this.props.value.start,
              endDate: this.props.value.end,
              ranges: ranges,
              locale,
              containerClass: "width-100",
              showDropdowns: true,
              alwaysShowCalendars: true,
              timePicker: true,
            }}
            onApply={(event, dateRangePicker) => {
              const { startDate, endDate } = dateRangePicker;
              this.handleRangeChange({ start: startDate.utc(true), end: endDate.utc(true), name: "Custom" });
              this.setState({rangeName: "Custom"});
            }}
          >
          <div className="dateRangePicker-display">
            <Icon className="header-text calendar"></Icon>
            <span id={(this.props.idPrefix?this.props.idPrefix:"")+"dateRange"}>{label}</span>
            <Icon className="header-text angle down"></Icon>
          </div>

          </DateRangePicker>
        </FormGroup>
      </div>
    );
  }
};
