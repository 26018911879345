import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Panel,
  Modal,
} from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import CustomDatePicker from '../components/CustomDatePicker';
import moment from 'moment';
import AutoAuthNavBar from './AutoAuthNavBar';
import AutoAuthBreadcrumbs from '../components/AutoAuthBreadcrumbs';
import { navbarSetup, resetUserData } from '../libs/utils';
import { Button } from 'react-bootstrap';
import { getCertActivity } from '../libs/db-lib';
import AlertModal from '../components/AlertModal';
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class CertRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showInfoModal: false,
      user: props.user,
      filter: '',
      certRequests: null,
      certRequestsList: [],
      currentCSR: {},
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      isLoading: false,

      open: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let result = await getCertActivity(
        this.state.startDate,
        this.state.endDate
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          certRequests: result.logEntries || [],
          certRequestsList: result.logEntries ? result.logEntries : [],
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        let processedStr = event.target.value.replace(
          /[.*+\-?^${}()|[\]\\]/g,
          '\\$&'
        );
        const filterArr = processedStr
          ? processedStr.split(' ').filter((f) => f !== '')
          : [];
        const filterStr = filterArr.reduce((f1, f2) => f1 + '|' + f2);
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const certRequestsList = this.state.certRequestsList.filter((u) => {
          return (
            regexStr.test(u.requestID) ||
            regexStr.test(u.clientID) ||
            regexStr.test(u.addedOn)
          );
        });
        this.setState({
          certRequestsList: certRequestsList ? certRequestsList : [],
        });
      } else {
        this.setState({
          certRequestsList: this.state.certRequests,
        });
      }
    }
  };

  handleRangeChange = async (value) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    }, () => {
      this.setState({
        isLoading: true
      })
    });

    try {
      let result = await getCertActivity(
        this.state.startDate,
        this.state.endDate
      );
      if (result.errorDesc) {
        throw new Error(result.errorDesc);
      } else {
        this.setState({
          certRequests: result.logEntries || [],
          certRequestsList: result.logEntries ? result.logEntries : [],
          isLoading: false
        });
      }
    } catch (e) {
      this.setState({
        alertMessage: e,
        showModal: true,
        isLoading: false,
      });
    }

    let filteredList = this.state.certRequests.filter((request) => {
      let momentDate = moment(request.addedOn);
      return value.start < momentDate && value.end > momentDate;
    });
    this.setState({
      certRequestsList: filteredList,
    });
  };

  handleShowInfoModal = (csr) => {
    this.setState({
      currentCSR: csr,
      showInfoModal: true,
    });
  };

  handleCancelModal = (e) => {
    this.setState({
      showModal: false,
      showInfoModal: false,
    });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  // Handle dummy download, for testing
  download = (isCsr, name, data) => {
    let formattedData = this.buildCertCsr(isCsr, data);

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(formattedData)
    );
    element.setAttribute('download', isCsr ? `${name}.csr` : `${name}.cer`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  buildCertCsr = (isCsr, data) => {
    let retStr = isCsr
      ? '-----BEGIN CERTIFICATE REQUEST-----'
      : '-----BEGIN CERTIFICATE-----';
    retStr += '\n';

    while (data.length > 0) {
      retStr += data.substr(0, 64) + '\n';
      data = data.substr(64);
    }
    retStr += isCsr
      ? '-----END CERTIFICATE REQUEST-----'
      : '-----END CERTIFICATE-----';

    return retStr;
  };

  buildCsv = () => {
    const header = ["Added On", "Certificate Data", "Certificate Expiration", "Certificate Issuance", "Certificate Serial",
                      "Client ID", "Client Label", "CSR Data", "DID Whitelist", "DLM Certificate Profile", "ECU ID", "ECU Variant",
                      "Manufacturing Plant", "MemSecWhitelist", "Organization", "Plant Equipment ID", "Request ID", "RID Whitelist",
                      "User Diag Role", "User First Name", "User ID", "User Last Name", "User Location", "User Source IP", "User Type"];


    let csvContent = header + "\r\n";

    this.state.certRequestsList.forEach((request) => {
      delete request.partitionID;
      let sortedKeys = ['addedOn', 'certData', 'certExpiryDate', 'certIssuanceDate', 'certSerial', 'clientID', 'clientLabel', 'csrData', 'DIDWhitelist',
                        'dlmCertProfile', 'ecuID', 'ecuVariant', 'manufacturingPlant', 'MemSecWhitelist', 'organization', 'plantEquipID', 'requestID',
                        'RIDWhitelist', 'userDiagRole', 'userFirstName', 'userID', 'userLastName', 'userLocation', 'userSourceIP', 'userType']
      let rowArray = [];

      for (let key of sortedKeys) {
        if (!request[key]) {
          rowArray.push("N/A");
          continue;
        }
        if (typeof request[key] === 'string') {
          rowArray.push(request[key] ? request[key].replace(/,/g, '-') : "N/A");
        } else {
          if (typeof request[key] === 'object') {
            rowArray.push(Object.keys(request[key]).length > 0 ? JSON.stringify(request[key]) : "N/A");
          }
        }
      }

      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent)
    );
    element.setAttribute('download', `${this.state.startDate.format("YYYY-MM-DD")} to ${this.state.endDate.format("YYYY-MM-DD")}-CSRs.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push({
      Header: 'Client Label',
      id: 'clientLabel',
      accessor: 'clientLabel',
      minWidth: 100,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Request ID',
      id: 'requestID',
      accessor: 'requestID',
      minWidth: 100,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'Added On',
      id: 'addedOn',
      accessor: 'addedOn',
      minWidth: 80,
      className: 'text-center',
    });
    columnDefs.push({
      Header: 'User ID',
      id: 'userID',
      accessor: 'userID',
      minWidth: 50,
      className: 'text-center',
    });

    columnDefs.push({
      Header: 'Request Status',
      id: 'requestStatus',
      accessor: 'requestStatus',
      minWidth: 50,
      className: 'text-center',
      Cell: (row) => {
        if (!row.original.requestStatus) {
          return "SUCCESS"
        }
        return row.original.requestStatus;
      }
    });

    columnDefs.push({
      Header: this.state.windowWidth > 500 ? 'Actions' : '',
      id: 'certRequestActions',
      accessor: 'requestID',
      minWidth: 100,
      maxWidth: 100,
      className: 'text-center',
      Cell: (row) => {
        return (
          <div>
            <ButtonGroup>
              <div>
                <Button
                  bsStyle="link"
                  href="#"
                  onClick={() =>
                    this.download(
                      true,
                      row.original.clientLabel,
                      row.original.csrData
                    )
                  }
                >
                  <Icon title="Download CSR" name="download" />
                </Button>
                {row.original.requestStatus !== 'ERROR' && row.original.certData &&
                  <Button
                    bsStyle="link"
                    href="#"
                    onClick={() =>
                      this.download(
                        false,
                        row.original.clientLabel,
                        row.original.certData
                      )
                    }
                  >
                    <Icon title="Download Certificate" name="certificate" />
                  </Button>
                }
                <Button
                  bsStyle="link"
                  href="#"
                  onClick={() => this.handleShowInfoModal(row.original)}
                >
                  <Icon title="Info" name="info circle" />
                </Button>
              </div>
            </ButtonGroup>
          </div>
        );
      },
      sortable: false,
      filterable: false,
    });

    let certRequests =
      this.state &&
      this.state.certRequestsList &&
      this.state.certRequestsList.length > 0
        ? this.state.certRequestsList.map((request) => {
            return request;
          })
        : [];

      let loadingText = <p>
        Loading client requests from <strong>{this.state.startDate.format("YYYY-MM-DD")}</strong> to <strong>{this.state.endDate.format("YYYY-MM-DD")}</strong>
      </p>

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    let userInfoMap = [
      ['userID', 'ID'],
      ['userDiagRole', 'Diag Role'],
      ['userType', 'Type'],
      ['userFirstName', 'First Name'],
      ['userLastName', 'Last Name'],
      ['userSourceIP', 'Source IP'],
      ['userLocation', 'Location'],
    ].map(([key, value]) => {
      return (
        <tr key={key}>
          <th>{value}:</th>
          <td>{this.state.currentCSR[key] || 'N/A'}</td>
        </tr>
      );
    });

    let csrInfoMap = [
      ['clientLabel', 'Client Label'],
      ['clientID', 'Client ID'],
      ['dlmCertProfile', 'DLM Certificate Profile'],
      ['certSerial', 'Certificate Serial'],
      ['certIssuanceDate', 'Certificate Issuance'],
      ['certExpiryDate', 'Certificate Expiration'],
    ].map(([key, value]) => {
      let retVal = "N/A";
      if (typeof this.state.currentCSR[key] === 'string') {
        retVal = this.state.currentCSR[key] || "N/A"
      } else if (typeof this.state.currentCSR[key] === 'object') {
        retVal = Object.keys(this.state.currentCSR[key]).length > 0 ? JSON.stringify(this.state.currentCSR[key]) : "N/A";
      }
      return (
        <tr key={key}>
          <th>{value}:</th>
          <td>{retVal}</td>
        </tr>
      );
    });

    let additionalInfoMap = [
      ['ecuVariant', 'ECU Variant'],
      ['ecuID', 'ECU ID'],
      ['plantEquipID', 'Plant Equipment ID'],
      ['manufacturingPlant', 'Manufacturing Plant'],
      ['organization', 'Organization'],
      ['DIDWhitelist', 'DID Whitelist'],
      ['MemSecWhitelist', 'Mem Sec Whitelist'],
      ['RIDWhitelist', 'RID Whitelist'],
    ].map(([key, value]) => {
      return (
        <tr key={key}>
          <th>{value}:</th>
          <td>{this.state.currentCSR[key] || 'N/A'}</td>
        </tr>
      );
    });

    return (
      <div className="home">
        <AutoAuthNavBar
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}
        ></AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">
          <Tabs defaultActiveKey={1} id="client-requests-tab">
            <Tab key="1" eventKey={1} title="Client Requests">
              <TabContent>
                {this.state.certRequests &&
                this.state.certRequests.length >= 0 ? (
                  <div
                    className={
                      this.state.windowWidth > 400
                        ? 'col-lg-12 col-md-11'
                        : 'col-lg-10 col-md-11 no-left-padding'
                    }
                  >
                    <br />
                    <div className="col-sm-9 center text-bold">Date Range:</div>
                    <br />
                    <br />
                    <div className="flex-container align-flex-start col-sm-9 center">
                      <CustomDatePicker
                        value={{
                          start: this.state.startDate,
                          end: this.state.endDate,
                          name: 'Last 30 Days',
                        }}
                        onChange={this.handleRangeChange}
                      />
                      <Button
                        bsStyle="primary"
                        onClick={this.buildCsv}
                        className="spacer15"
                      >
                        <Icon name="file alternate" />
                        Export CSV
                      </Button>
                    </div>

                    <br />
                    <div className="auto-auth-table col-sm-12">
                      <FormGroup controlId="filter" bsSize="large">
                        <ControlLabel
                          className={
                            this.state.filterFieldActivate ||
                            this.state.filter.length > 0
                              ? 'float-label field-active'
                              : 'float-label'
                          }
                        >
                          Filter
                        </ControlLabel>
                        <FormControl
                          maxLength="50"
                          type="text"
                          value={this.state.filter}
                          onChange={this.handleChange}
                          onFocus={this.activateField}
                          onBlur={this.disableField}
                        />
                      </FormGroup>
                      <ReactTable
                        columns={columnDefs}
                        data={certRequests}
                        noDataText={'No Certificate Requests Found'}
                        className="-highlight"
                        defaultPageSize={10}
                        defaultSorted={[
                          {
                            id: 'label',
                            desc: false,
                          },
                        ]}
                        loading={this.state.isLoading}
                        loadingText={loadingText}
                        getTrProps={(state, row, col, instance) => {
                          if (row && row.original.requestStatus === 'ERROR') {
                            return {
                              style: {
                                color: "red",
                                fontWeight: "bold"
                              }
                            }
                          }
                          return {};
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                )}
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancelModal}
        ></AlertModal>

        <Modal
          fullscreen="true"
          show={this.state.showInfoModal}
          onHide={this.handleCancelModal}
          dialogClassName="panel-frame"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.currentCSR.clientLabel} |{' '}
              {this.state.currentCSR.requestID}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex-container flex-container-center">
              { (this.state.currentCSR && this.state.currentCSR.errorMessage) ?
              <Panel bsStyle="danger">
              <Panel.Heading>
                <Panel.Title componentClass="h3">Error</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
              {this.state.currentCSR.errorMessage}
              </Panel.Body>
            </Panel> : null}
              <div />
              <div />
            </div>
            <div className="flex-container flex-container-space-evenly">
              <Panel bsStyle="primary" className="panel-30-percent">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">User Info</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <table>
                    <colgroup>
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      {userInfoMap}
                    </tbody>
                  </table>
                </Panel.Body>
              </Panel>
              <Panel bsStyle="primary" className="panel-30-percent">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">CSR Info</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <table>
                    <colgroup>
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      {csrInfoMap}
                    </tbody>
                  </table>
                </Panel.Body>
              </Panel>
              <Panel bsStyle="primary" className="panel-30-percent">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Additional</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <table>
                    <colgroup>
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      {additionalInfoMap}
                    </tbody>
                  </table>
                </Panel.Body>
              </Panel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={this.handleCancelModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
